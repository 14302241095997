import moment from "moment";
import { AuditedSerializable } from "./base";

export class User extends AuditedSerializable<User> {
  get net30(): boolean {
    return this.hasTag("NET30");
  }

  set net30(net30: boolean) {
    this.updateTags(undefined, net30, undefined);
  }

  get reseller(): boolean {
    return this.hasTag("RESELLER");
  }

  set reseller(reseller: boolean) {
    this.updateTags(reseller, undefined, undefined);
  }

  get vip(): boolean {
    return this.hasTag("VIP");
  }

  set vip(vip: boolean) {
    this.updateTags(undefined, undefined, vip);
  }

  get fullName(): string {
    let fullName = "";
    if (this.firstName) {
      fullName = this.firstName + " ";
    }
    if (this.lastName) {
      fullName += this.lastName;
    }
    if (fullName === "") {
      fullName = this.username ?? "";
    }
    return fullName;
  }

  static EMPTY: User = new User();
  userId: number | undefined;
  username: string | undefined;
  firstName: string | undefined;
  companyName: string | undefined;
  lastName: string | undefined;
  password: string | undefined; // Only used when setting password
  guest: boolean | undefined;
  phone: string | undefined;
  note: string | undefined;
  lastLogin: Date | undefined;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
  roles: string[] = [];
  groups: string[] = [];

  // Shopify Data
  shopifyId: string | undefined;
  tags: string | undefined;
  taxExempt: boolean | undefined;
  blocked: boolean | undefined;
  locked: boolean | undefined;
  ordersCount: number | undefined;
  totalSpent: number | undefined;
  lastOrderId: string | undefined;
  lastOrderName: string | undefined;
  addresses: Address[] = [];
  defaultAddress: Address | undefined;

  override deserialize(input: any): User {
    super.deserialize(input);
    this.username = input.username;
    this.companyName = input.companyName;
    this.firstName = input.firstName;
    this.lastName = input.lastName;
    this.guest = input.guest;
    if (input.createdAt) {
      this.createdAt = moment.utc(input.createdAt).toDate();
    }
    if (input.updatedAt) {
      this.updatedAt = moment.utc(input.updatedAt).toDate();
    }
    // console.log(input.username + ' ' + input.lastLogin);
    if (input.lastLogin && input.lastLogin > 0) {
      this.lastLogin = moment.utc(input.lastLogin).toDate();
    }
    this.note = input.note;
    this.shopifyId = input.shopifyId;
    this.phone = input.phone;
    this.ordersCount = input.ordersCount;
    this.totalSpent = input.totalSpent;
    this.lastOrderId = input.lastOrderId;
    this.lastOrderName = input.lastOrderName;
    if (input.defaultAddress) {
      this.defaultAddress = new Address().deserialize(input.defaultAddress);
    }
    if (input.addresses) {
      (input.addresses as any[]).forEach((address) => {
        this.addresses.push(new Address().deserialize(address));
      });
    }
    this.roles = [];
    if (input.roles) {
      (input.roles as any[]).forEach((authority) => {
        this.roles.push(authority);
      });
    }
    this.groups = [];
    if (input.groups) {
      (input.groups as any[]).forEach((group) => {
        this.groups.push(group);
      });
    }
    this.tags = input.tags;
    this.taxExempt = input.taxExempt;
    this.blocked = input.blocked;
    this.locked = input.locked;
    return this;
  }

  updateGroups(group: string) {
    if (this.tags?.includes("RESELLER") && group !== "") {
      this.groups[0] = group;
    } else {
      this.groups = [];
    }
  }

  updateTags(reseller: boolean | undefined, net30: boolean | undefined, vip: boolean | undefined) {
    this.updateTag("RESELLER", reseller);
    this.updateTag("NET30", net30);
    this.updateTag("VIP", vip);
  }

  private updateTag(name: string, value: boolean | undefined) {
    let tags: any[] = [];
    if (this.tags) {
      tags = this.tags.split(",");
    }
    for (let tag of tags) {
      tag = tag.replace(/^\s|\s$/, "");
    }
    if (value !== undefined) {
      const tag = tags.indexOf(name);
      if (tag > -1) {
        // console.log('remove role RESELLER');
        tags.splice(tag, 1);
        // console.log('update roles', roles, this.roleReseller);
      }
      if (value) {
        tags.push(name);
      }
    }
    this.tags = "";
    if (tags && tags.length > 0) {
      this.tags = tags.reduceRight((previousValue, currentValue) => previousValue + "," + currentValue);
    }
  }

  updateRoles(reseller: boolean) {
    const roles = this.roles;
    // console.log('roles', roles);
    const start = roles.indexOf("ROLE_RESELLER");
    if (start > -1) {
      // console.log('remove role RESELLER');
      roles.splice(start, 1);
      // console.log('update roles', roles, this.roleReseller);
    }
    if (reseller) {
      // console.log('add role RESELLER');
      roles.push("ROLE_RESELLER");
    }
    this.roles = roles;
    // console.log('roles', roles, this.user.roles);
  }

  private hasTag(name: string) {
    let value = false;
    if (this.tags) {
      value = this.tags.indexOf(name) !== -1;
    }
    return value;
  }
}

export class FormattedAddress extends AuditedSerializable<FormattedAddress> {
  name: string | undefined;
  formatted: string[] = [];
  formattedArea: string | undefined;
  phone: string | undefined;

  override deserialize(input: any): FormattedAddress {
    super.deserialize(input);
    this.name = input.name;
    (input.formatted as string[]).forEach((value) => {
      this.formatted.push(value);
    });
    this.formattedArea = input.formattedArea;
    this.phone = input.phone;
    return this;
  }
}

export class Address extends AuditedSerializable<Address> {
  name: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  phoneNumber: string | undefined;
  address1: string | undefined;
  address2: string | undefined;
  city: string | undefined;
  zip: string | undefined;
  company: string | undefined;
  country: string | undefined;
  countryCode: string | undefined;
  state: string | undefined;
  stateCode: string | undefined;
  contactEmail: string | undefined;

  override deserialize(input: any): Address {
    super.deserialize(input);
    this.name = input.name;
    this.firstName = input.firstName;
    this.lastName = input.lastName;
    this.phoneNumber = input.phone;
    this.address1 = input.address1;
    this.address2 = input.address2;
    this.city = input.city;
    this.zip = input.zip;
    this.company = input.company;
    this.country = input.country;
    this.countryCode = input.country_code;
    this.state = input.state;
    this.stateCode = input.state_code;
    this.contactEmail = input.contact_email;
    return this;
  }
}
