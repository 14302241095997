import {
  Component,
  ElementRef,
  OnInit,
  Signal,
  ViewChild,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";
import { ClientSessionStateService } from "../../services/sessionState.service";
import { ClientSessionStateModel } from "../../models/sessionState.model";
import { FXAuthService } from "../../services/fx-auth.service";

@Component({
  selector: "app-connected-better",
  standalone: true,
  imports: [FormsModule, MatFormFieldModule],
  templateUrl: "./connected-better.component.html",
  styleUrl: "./connected-better.component.scss",
})
export class ConnectedBetterComponent implements OnInit {
  @ViewChild("auth", { static: false }) authFormElement!: ElementRef;
  loginUrl: SafeUrl = "";
  productConfigData = "";
  configurationData = "";
  formKey = "";

  state!: Signal<ClientSessionStateModel>;
  constructor(
    private sanitized: DomSanitizer,
    private stateSvc: ClientSessionStateService,
    private authSvc: FXAuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this.state = this.stateSvc.getState();
    let authSessionData = this.state().authSessionData;
    if (!authSessionData?.loginUrl) {
      await this.authSvc.getAuthData().then((res: any) => {
        res.userId = undefined;
        this.stateSvc.updateState({ authSessionData: res });
        authSessionData = res;
      });
    }

    this.loginUrl = this.sanitized.bypassSecurityTrustResourceUrl(authSessionData.loginUrl);
    this.productConfigData = authSessionData.productConfigData;
    this.configurationData = `{}`;
    this.formKey = authSessionData.formKey;
  }
}
