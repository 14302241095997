import { Component } from "@angular/core";
import { PageContentWrapperComponent } from "../../features/page-content-wrapper/page-content-wrapper.component";


@Component({
    selector: "app-empty",
    standalone: true,
    template: ` 
  <div class="page-container">
    <app-page-content-wrapper [goBack]="goBack">
      <div class="page-title">
        <h1></h1>
      </div>
      <div class="page-content"></div>
    </app-page-content-wrapper>
  </div>`,
    styles: [],
    imports: [PageContentWrapperComponent]
})
export class EmptyComponent {
  goBack() {
    console.log("Go back");
  }
}
