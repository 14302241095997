import { EventEmitter, Component, OnInit, Output, Input } from '@angular/core';
import {environment} from '../../../../environments/environment';
import { User } from '../../../../../../libs/models/src/lib/user';
import { ImagesService } from '../../../services/images.service';

import { LoadingModalComponent } from '../../../features/loading-modal/loading-modal.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
@Component({
    selector: "app-pixabay-modal",
    standalone: true,
    templateUrl: "./pixabay-modal.component.html",
    styleUrls: ["./pixabay-modal.component.scss"],
    imports: [
        LoadingModalComponent,
        MatExpansionModule,
        FormsModule,
        MatSelectModule,
        MatCheckboxModule
    ]
})
export class PixabayModalComponent implements OnInit {
  public isPixabayAdvancedSearchActive = false;
  public selectedCategory: any;
  public selectedImageType: any;
  public searchText: any;
  public pixabays: any[] = [];
  public loading = false;

  public pixabayLoading = false;

  @Input() containerId: string = "";
  @Input() user: User | undefined;
  @Output() pixabayAdded: EventEmitter<any> = new EventEmitter();

  public pixabayImageTypes = ["Vector", "Photo", "Illustration"];

  public pixabayColors = ["Grayscale", "Transparent"];

  private selectedColors: any[] = [];

  public pixabayCategories = [
    "Fashion",
    "Nature",
    "Backgrounds",
    "Science",
    "Education",
    "People",
    "Feelings",
    "Religion",
    "Health",
    "Places",
    "Animals",
    "Industry",
    "Food",
    "Computer",
    "Sports",
    "Transportation",
    "Travel",
    "Buildings",
    "Business",
    "Music",
  ];

  constructor(private imagesService: ImagesService) {}

  ngOnInit() {}

  toggleColor(color: string) {
    const index = this.selectedColors.indexOf(color);
    if (index > -1) {
      this.selectedColors.splice(index, 1);
    } else {
      this.selectedColors.push(color);
    }
  }

  addPixabay(pixabay: any) {
    // this.pixabayAdded.emit()
    console.log("We are preparing to add pixabay with: ", pixabay);
    this.pixabayLoading = true;

    let pixabayUrl;
    let convertNeeded;
    if (pixabay.type === "illustration" || pixabay.type === "photo") {
      pixabayUrl = pixabay.fullHDURL;
      convertNeeded = false;
    } else if (pixabay.type === "vector/svg") {
      pixabayUrl = pixabay.vectorURL;
      convertNeeded = false;
    } else if (pixabay.type === "vector/ai") {
      pixabayUrl = pixabay.vectorURL;
      convertNeeded = true;
    }

    if (!convertNeeded) {
      this.imagesService
        .uploadPixabay(this.containerId, pixabayUrl)
        .then((res: any) => {
          const file = {
            tempBucketName: res.file.bucket,
            tempFilename: res.file.filename,
            type: res.file.type,
          };

          if (this.user) {
            this.imagesService
              .uploadImageToUserLibrary(file, this.user.id!)
              .then(() => {
                this.imagesService
                  .copyImageToContainer(file, this.containerId)
                  .then(() => {
                    this.pixabayAdded.emit();
                    this.pixabayLoading = false;
                  })
                  .catch(() => {
                    this.pixabayLoading = false;
                  });
              })
              .catch(() => {
                this.pixabayLoading = false;
              });
          } else {
            this.imagesService
              .uploadImageToContainer(file, this.containerId)
              .then(() => {
                this.pixabayAdded.emit();
                this.pixabayLoading = false;
              })
              .catch(() => {
                this.pixabayLoading = false;
              });
          }
        })
        .catch((error: any) => {
          console.log(error);
          this.pixabayLoading = false;
        });
    } else {
      this.imagesService
        .convertContentFromUrl(this.containerId, pixabayUrl)
        .then((response: any) => {
          this.pixabayLoading = false;
          console.log("Converting gave us response: ", response);
        })
        .catch(() => {
          this.pixabayLoading = false;
        });
    }
  }

  getPixabay() {
    this.loading = true;
    const url = this.preparePixabayUrl();
    this.pixabays = [];
    this.imagesService
      .searchPixabay(url)
      .then((response: { total: number; hits: any[] }) => {
        if (response.total === 0) {
          this.loading = false;
        }
        response.hits.forEach((hit: any) => {
          this.pixabays.push(hit);
          this.loading = false;
        });
      })
      .catch(() => {
        this.loading = false;
      });
  }

  clearColors() {
    this.selectedColors = [];
  }
  public trackByFn(index: any, item: any) {
    if (!item) {
      return null;
    } else {
      return item.id;
    }
  }
  preparePixabayUrl(): string {
    let url = `${environment.apiHost}/project/external/image/pixabay?query=${this.searchText}`;

    if (this.selectedImageType) {
      url += `&type=${this.selectedImageType}`;
    }
    if (this.selectedCategory) {
      url += `&category=${this.selectedCategory}`;
    }

    if (this.selectedColors.length > 0) {
      url += `&colors=`;
      for (const color of this.selectedColors) {
        url += color + ",";
      }
      // removes extra comma from end of string
      url = url.substring(0, url.length - 1);
    }

    return url;
  }
}
