import { HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { interval, lastValueFrom, startWith, Subscription, switchMap } from "rxjs";
import { ClientSessionStateService } from "./sessionState.service";
import { User } from "../../../../libs/models/src/lib/user";
import { AuthService } from "../../../../libs/services/src/lib/auth.service";
import LogRocket from "logrocket";

@Injectable({
  providedIn: "root",
})
export class FXAuthService extends AuthService {
  healthCheckPolling = Subscription.EMPTY;
  user: User | undefined;
  private stateSvc: ClientSessionStateService = inject(ClientSessionStateService);
  
  constructor() {
    super();
    if (environment.config.enableHealthCheckPolling && this.healthCheckPolling == Subscription.EMPTY) {
      this.healthCheckPolling = interval(10000)
        .pipe(
          startWith(0),
          switchMap(() => this.healthCheck())
        )
        .subscribe((loggedIn) => {
          if (!loggedIn && this.stateSvc.getStateValue("sessionKey")) {
            this.getAuthData().then((res: any) => {
              res.userId = undefined;
              this.stateSvc.updateState({ authSessionData: res });
            });
          }
        });
    }
  }

  async getAuthData() {
    try {
      const url = `${environment.apiHost}/ecom/auth`;

      const res = await lastValueFrom(
        this.httpClient.get(url, {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        })
      );
      return res;
    } catch (error) {
      this.logger.error("Error getting auth data", error);
      return error;
    }
  }

  async healthCheck(): Promise<boolean> {
          if (this.stateSvc.getStateValue("sessionKey")) {
            try {
              this.httpClient
                .post(
                  environment.config.healthCheckUrl,
                  {
                    seller_id: environment.config.fedexSellerId,
                  },
                  {
                    headers: new HttpHeaders({
                      "Content-Type": "application/json",
                    }),
                    withCredentials: true,
                  }
                )
                .subscribe(
                  (_res) => {
                    console.log("User is logged in");
                    return Promise.resolve(true);
                  },
                  () => {
                    console.log("User is not logged in");
                    this.user = undefined;
                    return Promise.resolve(false);
                  }
                );
            } catch (error: any) {}
          }
    return Promise.resolve(false);
  }

  async getCurrentUser(): Promise<User> {
    if (this.user) {
      return Promise.resolve(this.user);
    } else {
      try {
        const res = await lastValueFrom(this.httpClient.get(`${environment.apiHost}/ecom/users/current`));

        this.user = new User().deserialize(res);
        LogRocket.identify(this.user.toString(), {
            name: this.user.toString(),
        });
        return this.user;
      } catch (error: any) {
        this.logger.error(error);
        return Promise.reject(error);
      }
    }
  }

  getUser(): User | undefined {
    return this.user;
  }

  override logout(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.httpClient.post(`${environment.apiHost}/user/logout`, null).subscribe(
        (_response: any) => {
          this.logger.info("User logged out");
          this.stateSvc.clearState();
          resolve(true);
        },
        (error) => {
          console.log(error);
          this.logger.debug("Unable to log out user", error);
          reject(error);
        }
      );
    });
  }
}
