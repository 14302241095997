import { Dictionary } from "../../../utils/src/lib/collections";
import { ISerializable } from "./base";

export class Price implements ISerializable<Price> {
  msf: number | undefined;
  baseMsf: number | undefined;
  pricePerUnit: number | undefined;
  unitsPerCase: number | undefined; // only available for items sold by the case
  luxeMinimumOrder: number = 0;
  totalPrice: number | undefined;
  discountRate: number | undefined;
  showDiscount: boolean | undefined;
  minimumOrder: number = 0;
  maxQuantity: number = 0;
  rushIncrease: number | undefined;
  quantity: number = 0;
  type: string | undefined;
  base: string | undefined;
  pricePerCase: number | undefined
  label = '';
totalSheets: string = '';
pricePerReam: any = '';

  deserialize(input: any): Price {
    this.msf = input.msf;
    this.baseMsf = input.baseMsf;
    this.pricePerUnit = input.pricePerUnit;
    this.unitsPerCase = input.unitsPerCase;
    this.luxeMinimumOrder = input.luxeMinimumOrder;
    this.totalPrice = input.totalPrice;
    this.discountRate = input.discountRate;
    this.showDiscount = input.showDiscount;
    this.minimumOrder = input.minimumOrder;
    this.maxQuantity = input.maxQuantity;
    this.rushIncrease = input.rushIncrease;
    this.quantity = input.quantity;
    this.type = input.type;
    this.base = input.base;
    return this;
  }
}

export class PriceDebug implements ISerializable<PriceDebug> {
  adjustments!: Dictionary<string, number>;

  deserialize(input: any): PriceDebug {
    this.adjustments = new Dictionary<string, number>();
    if (input.adjustments) {
      (Object.getOwnPropertyNames(input.adjustments) as string[]).forEach(
        (value) => {
          if (input.adjustments[value] !== 0) {
            this.adjustments.add(value, input.adjustments[value]);
          }
        }
      );
    }
    return this;
  }
}