import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, Router } from '@angular/router';

import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { LoggerModule } from 'ngx-logger';
import { environment } from '../environments/environment';
import { ModalModule } from 'ngx-bootstrap/modal';
import { sessionInterceptor } from './sessionInterceptor';  
import { logInterceptor } from './logInterceptor';
import { AUTH_SVC, ENVIRONMENT } from '../../../libs/models/src/lib/injection.tokens';
import { FXAuthService } from './services/fx-auth.service';


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([sessionInterceptor, logInterceptor])),
    importProvidersFrom([
      BrowserAnimationsModule,
      LoggerModule.forRoot({
        serverLoggingUrl: environment.logger.serverLoggingUrl,
        level: environment.logger.logLevel,
        serverLogLevel: environment.logger.serverLogLevel,
      }),
      ModalModule.forRoot(),
    ]),
    provideAnimations(),
    { provide: ENVIRONMENT, useValue: environment },
    { provide: AUTH_SVC, useClass: FXAuthService }
  ],
};
