import {Component, ElementRef, OnInit, Signal} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AmbientLight, PerspectiveCamera, PointLight, Scene, Vector2, WebGLRenderer} from 'three';
import {HttpClient} from '@angular/common/http';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import {environment} from '../../../../environments/environment';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader.js';
import { ClientSessionStateService } from '../../../services/sessionState.service';
import { Subscription } from 'rxjs';
import { ClientSessionStateModel } from '../../../models/sessionState.model';



@Component({
    selector: "app-upload-preview",
    standalone: true,
    templateUrl: "./upload-preview.component.html",
    styleUrls: ["./upload-preview.component.scss"],
    imports: []
})
export class UploadPreviewComponent implements OnInit {
  private apiURL = "/project/container/";
  private previewName = "3DPreview";
  private uploadDetails: any;
  private container: any;
  private controls!: OrbitControls;
  private renderer!: WebGLRenderer;
  private scene!: Scene;
  private camera!: PerspectiveCamera;
  private dimensions: any;

  public valid = true;
  state!: Signal<ClientSessionStateModel>;
  subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private element: ElementRef,
    private http: HttpClient,
    private stateSvc: ClientSessionStateService
  ) {
    this.animate = this.animate.bind(this);
    this.initPreview = this.initPreview.bind(this);
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.state = this.stateSvc.getState();
    this.container = this.element.nativeElement.querySelector("#container");
    this.route.params.subscribe((params) => {
      this.uploadDetails = this.state().uploadDetails;
      this.http
        .get(`${environment.apiHost}${this.apiURL}${this.uploadDetails.ContainerId}/info`)
        .subscribe(async (info: any) => {
          this.dimensions = info.info;
          await this.initPreview();
          await this.updatePreview();
          this.animate();
        });
    });
  }

  async initPreview() {
    this.scene = new Scene();

    this.camera = new PerspectiveCamera(5, 400 / 300, 1, 1000);
    this.camera.position.set(4.5, 2, 0);

    const ambientLight = new AmbientLight(0xffffff, 0.2);
    const pointLight = new PointLight(0xffffff, 0.8);
    this.scene.add(ambientLight);
    this.scene.add(this.camera);
    this.camera.add(pointLight);

    this.renderer = new WebGLRenderer({ antialias: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(400, 300);
    this.renderer.shadowMap.enabled = true;
    this.renderer.setClearColor(0xffffff);
    this.container.appendChild(this.renderer.domElement);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableZoom = true;
    this.controls.minZoom = 0.5;
    this.controls.maxZoom = 2;
    this.controls.screenSpacePanning = true;
    this.controls.minDistance = 1;
    this.controls.maxDistance = 5;

    if (this.uploadDetails.Category === "Event Boxes") {
      this.controls.minZoom = 1;
      this.controls.maxZoom = 5;
      this.camera.fov = 20;
      this.camera.position.set(15, 5, 0);
      this.controls.minDistance = 1;
      this.controls.maxDistance = 20;
    }
    this.controls.update();
  }

  updatePreview() {
    const selectedObject = this.scene?.getObjectByName(this.previewName);
    if (selectedObject !== null && selectedObject !== undefined) {
      this.scene?.remove(selectedObject);
    }

    const colladaLoader = new ColladaLoader();

    const url = environment.apiHost + this.apiURL + this.uploadDetails.ContainerId + "/Box.dae";
    colladaLoader.load(url, (collada) => {
      const avatar = collada.scene;
      const library: any = collada.library;
      const materials = library.materials;

      // Zoom textures in
      const maxDim = Math.max(this.dimensions.width, this.dimensions.height);
      const zoom = maxDim / (maxDim + 0.5);
      const insideMaterial = materials["1InsideOneUp"] || materials["1Inside" + this.uploadDetails.Subcategory] || null;
      const outsideMaterial =
        materials["0OutsideOneUp"] || materials["0Outside" + this.uploadDetails.Subcategory] || null;

      this.valid = insideMaterial != null && outsideMaterial != null;

      insideMaterial.build.map.repeat = new Vector2(zoom, zoom);
      outsideMaterial.build.map.repeat = new Vector2(zoom, zoom);

      // Offset Textures correctly
      const offset = 0.25 / (maxDim + 0.5);
      insideMaterial.build.map.offset = new Vector2(-offset, -offset);
      outsideMaterial.build.map.offset = new Vector2(offset, offset);

      // Flip inside to correct orientation
      insideMaterial.build.map.flipY = false;
      insideMaterial.build.map.rotation = Math.PI;
      avatar.name = "3DPreview";
      if (this.uploadDetails.Category === "Event Boxes") {
        avatar.translateY(-0.4);
      } else {
        avatar.translateY(-0.05);
      }
      this.scene?.add(avatar);
    });
  }

  animate() {
    requestAnimationFrame(this.animate);

    // required if controls.enableDamping or controls.autoRotate are set to true
    this.controls?.update();

    this.renderer.render(this.scene, this.camera);
  }
}
