import { Injectable, signal } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  public errorOpen = signal<boolean>(false);
  public alertMsg = signal<string>("");
  public alertType = signal<string>("danger");
  public alertTimeout = signal<string>("5000");

  showError(payload: any) {
    this.alertType.set("danger");
    this.alertMsg.set(payload.msg);
    this.errorOpen.set(true);
  }

  showWarning(payload: any) {
    this.alertType.set("warning");
    this.alertMsg.set(payload.msg);
    this.errorOpen.set(true);
  }

  showInfo(payload: any) {
    this.alertType.set("info");
    this.alertMsg.set(payload.msg);
    this.errorOpen.set(true);
  }

  showSuccess(payload: any) {
    this.alertType.set("success");
    this.alertMsg.set(payload.msg);
    this.errorOpen.set(true);
  }

  clear() {
    this.alertType.set("danger");
    this.alertMsg.set('');
    this.errorOpen.set(false);
  }
}
