import { Component, ElementRef, Inject, OnInit, signal, Signal, TemplateRef, ViewChild } from "@angular/core";
import { SafeResourceUrl, DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ERROR_BODY, ERROR_TITLE } from "../../../globals";
import { ConfirmationModalComponent } from "../../features/confirmation-modal/confirmation-modal.component";
import { BoxService } from "../../services/box.service";
import { CheckoutService } from "../../services/checkout.service";
import { ContainerService } from "../../services/container.service";
import { ProjectService } from "../../services/project.service";
import Fractions from "../../../../../libs/utils/src/lib/fractions";
import { UploadPreviewComponent } from "./upload-preview/upload-preview.component";
import { ClientSessionStateService } from "../../services/sessionState.service";
import { Subscription } from "rxjs";
import { ClientSessionStateModel } from "../../models/sessionState.model";
import { OrderService } from "../../services/order.service";
import { LoadingModalComponent } from "../../features/loading-modal/loading-modal.component";
import { CommonModule } from "@angular/common";
import { NgxDropzoneModule } from "ngx-dropzone";
import { FormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { PageContentWrapperComponent } from "../../features/page-content-wrapper/page-content-wrapper.component";
import { FXAuthService } from "../../services/fx-auth.service";
import { AUTH_SVC } from "../../../../../libs/models/src/lib/injection.tokens";
import { AuthService } from "../../../../../libs/services/public-api";
import { AlertService } from "../../../../../libs/services/src/lib/alert.service";

declare var window: any;

@Component({
  selector: "app-upload-design",
  standalone: true,
  templateUrl: "./upload-design.component.html",
  styleUrls: ["./upload-design.component.scss"],
  imports: [
    CommonModule,
    PageContentWrapperComponent,
    LoadingModalComponent,
    NgxDropzoneModule,
    FormsModule,
    MatSelectModule,
    UploadPreviewComponent,
  ],
})
export class UploadDesignComponent implements OnInit {
  @ViewChild(UploadPreviewComponent, { static: false }) uploadPreview!: UploadPreviewComponent;
  @ViewChild("notFoundModal", { static: false }) notFoundModal!: TemplateRef<any>;
  @ViewChild("addToCartForm", { static: false }) addToCartForm!: ElementRef;

  cartUrl = signal<string>("");
  cxmlurlencoded = signal<string>("");

  public modal: BsModalRef | undefined;
  public outsideImageSrc: SafeResourceUrl | undefined;
  public insideImageSrc: SafeResourceUrl | undefined;
  public base64: string | ArrayBuffer | null | undefined;

  public uploadingFile = false;
  public deletingFile = false;
  public outsideFileUploaded = false;
  public insideFileUploaded = false;
  public activeButton = "outside";
  isPaddedMailer = false;
  public design: any = {};
  public cartLoading: boolean = false;
  public pageLoading: boolean = false;
  public uploadInProgress: boolean = false;
  public customQuantityEntered = false;

  public length: number = 0;
  public width: number = 0;
  public depth: number = 0;
  public outsideOnly: boolean = false;
  public printingSide: string = "";
  public paperName: string = "";

  public selectedPrice: any = {};
  public prices: any[] = [];

  private quantities: number[] = [];
  private standardQuantities = [1, 10, 25, 50, 100, 250, 500, 1000, 1500, 2000, 5000, 10000, 12000];
  private luxeQuantities = [500, 1000, 2000, 3000, 5000, 8000, 10000];

  public boxData: any;
  public customQuantityViolation: boolean = false;
  enteredCustomPrice: string = "";
  public date = new Date();
  state!: Signal<ClientSessionStateModel>;
  subscriptions: Subscription = new Subscription();
  disableBtn = signal<boolean>(false);

  constructor(
    private sanitizer: DomSanitizer,
    private containerService: ContainerService,
    @Inject(AUTH_SVC) private authService: AuthService,
    private orderService: OrderService,
    private boxService: BoxService,
    private checkoutService: CheckoutService,
    private projectService: ProjectService,
    private router: Router,
    private bsModalService: BsModalService,
    private stateSvc: ClientSessionStateService,
    private alertSvc: AlertService
  ) {}

  insideFiles: File[] = [];
  outsideFiles: File[] = [];
  fileName: SafeUrl | undefined;

  async ngOnInit() {
    window.scroll(0, 0);
    this.pageLoading = true;
    this.state = this.stateSvc.getState();

    if (!this.state().uploadDetails.twoSided && !this.state().uploadDetails.insideOnly) {
      this.outsideOnly = true;
    }
    if (this.state().uploadDetails.insideOnly) {
      this.activeButton = "inside";
    } else if (!this.state().uploadDetails.insideOnly) {
      this.activeButton = "outside";
    }
    this.printingSide = this.getPrintingSide();
    this.paperName = this.getPaperName(this.state().uploadDetails.kraftType);

    this.containerService
      .getContainerInfo(this.state().uploadDetails.ContainerId)
      .then((containerInfo: { design: any }) => {
        this.design = containerInfo.design;
        const dimensions = this.design.dimensions!.split("x");
        this.length = parseInt(dimensions[0] ?? 0);
        this.width = parseInt(dimensions[1] ?? 0);
        this.depth = parseInt(dimensions[2] ?? 0);

        this.isPaddedMailer = this.state().uploadDetails.Code.startsWith("RPM");
        if (this.isPaddedMailer) {
          this.listPaddedMailerPrices();
        } else {
          this.listPrices();
        }
        this.pageLoading = false;
      })
      .catch(() => {
        this.insideFileUploaded = true;
        this.uploadingFile = false;
        this.pageLoading = false;
        this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    this.boxService
      .getType(this.state().uploadDetails.Code)
      .then((response: any) => {
        this.boxData = response;
        this.boxData.flute = response.boardType.flute;
        this.boxData.overlay = false;
        this.boxData.preConstructedZip = false;
        this.boxData.options = [];
        this.boxData.nameAsHtml = {};
        this.boxData.defaultQuantity = 200;
      })
      .catch(() => {
        this.insideFileUploaded = false;
        this.uploadingFile = false;
        this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
    await (this.authService as FXAuthService)
      .getCurrentUser()
      .then((res: any) => {})
      .catch(() => {
        this.insideFileUploaded = false;
        this.uploadingFile = false;
      });
  }

  processDesign(event: { addedFiles: File[] }) {
    const fileName = event.addedFiles[0].name;

    console.log(fileName.slice(fileName.length - 3));

    if (!(fileName.slice(fileName.length - 3) === "pdf")) {
      this.alertSvc.showError({ msg: "Please upload a .pdf" });
      return;
    }

    this.uploadingFile = true;
    const formData: FormData = new FormData();
    console.log(
      `Uploading '${event.addedFiles[0].name}' to '${this.state().uploadDetails.ContainerId}' as ${
        this.activeButton
      } art`
    );

    this.uploadInProgress = true;
    formData.append("file", event.addedFiles[0]);
    formData.append("filename", "offline_" + this.activeButton + ".pdf");

    this.containerService
      .uploadBasicDesignFile(this.state().uploadDetails.ContainerId, formData)
      .then((response: any) => {
        console.log("Upload basic design file gave: ", response);

        if (this.activeButton === "outside") {
          this.uploadOutsideImage(response, formData);
        } else {
          this.uploadInsideImage(response, formData);
        }
      })
      .catch(() => {
        this.uploadingFile = false;
        this.uploadInProgress = false;
        this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  uploadInsideImage(response: any, formData: any) {
    this.orderService
      .getConvertedContent(response.convert.jobId, response.container, "Texture_2_original.png")
      .then(async (content: any) => {
        this.containerService
          .getContainerFiles(this.state().uploadDetails.ContainerId)
          .then((containerFiles: { files: any[] }) => {
            this.insideImageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
              "data:image/jpeg;base64, " +
                containerFiles.files.find((x: { name: string }) => x.name === "Texture_2_original.png").content
            );

            this.containerService
              .storeTexture(
                this.state().uploadDetails.ContainerId,
                "Texture_2.png",
                "data:image/png;base64," +
                  containerFiles.files.find((x: { name: string }) => x.name === "Texture_2_original.png").content
              )
              .then(() => {
                this.insideFileUploaded = true;
                this.uploadingFile = false;
                this.uploadPreview.updatePreview();
              })
              .catch((reason: any) => {
                this.insideFileUploaded = true;
                this.uploadingFile = false;
                this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
              });
            if (!this.state().uploadDetails.twoSided) {
              this.containerService
                .storeTexture(
                  this.state().uploadDetails.ContainerId,
                  "screenshot.png",
                  "data:image/png;base64," +
                    containerFiles.files.find((x: { name: string }) => x.name === "Texture_2_original.png").content
                )
                .then(() => {
                  this.insideFileUploaded = true;
                  this.uploadingFile = false;
                })
                .catch((reason: any) => {
                  this.insideFileUploaded = true;
                  this.uploadingFile = false;
                  this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
                });
            }
          })
          .catch(() => {
            this.insideFileUploaded = true;
            this.uploadingFile = false;
            this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
          });
      })
      .catch(() => {
        this.insideFileUploaded = true;
        this.uploadingFile = false;
        this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  uploadOutsideImage(response: any, formData: any) {
    this.orderService
      .getConvertedContent(response.convert.jobId, response.container, "Texture_0_original.png")
      .then(async (content: any) => {
        this.containerService
          .getContainerFiles(this.state().uploadDetails.ContainerId)
          .then((containerFiles: { files: any[] }) => {
            this.outsideImageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
              "data:image/jpeg;base64, " +
                containerFiles.files.find((x: { name: string }) => x.name === "Texture_0_original.png").content
            );

            this.containerService
              .storeTexture(
                this.state().uploadDetails.ContainerId,
                "Texture_0.png",
                "data:image/png;base64," +
                  containerFiles.files.find((x: { name: string }) => x.name === "Texture_0_original.png").content
              )
              .then(() => {
                this.outsideFileUploaded = true;
                this.uploadingFile = false;
                this.uploadPreview.updatePreview();
              })
              .catch(() => {
                this.insideFileUploaded = true;
                this.uploadingFile = false;
                this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
              });
            this.containerService
              .storeTexture(
                this.state().uploadDetails.ContainerId,
                "screenshot.png",
                "data:image/png;base64," +
                  containerFiles.files.find((x: { name: string }) => x.name === "Texture_0_original.png").content
              )
              .then(() => {
                this.outsideFileUploaded = true;
                this.uploadingFile = false;
                this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
              })
              .catch(() => {
                this.insideFileUploaded = true;
                this.uploadingFile = false;
                this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
              });
          });
      })
      .catch((reason: any) => {
        this.insideFileUploaded = true;
        this.uploadingFile = false;
        this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  convertToBase64(pdf: Blob): Promise<void> {
    return new Promise<void>((resolve) => {
      const fileReader = new FileReader();
      // Onload of file read the file content
      fileReader.onload = () => {
        this.base64 = fileReader.result;
        resolve();
      };
      // Convert data to base64
      fileReader.readAsDataURL(pdf);
    });
  }

  getPrintingSide() {
    if (this.state().uploadDetails.twoSided) {
      return "2-Sided";
    } else if (this.state().uploadDetails.insideOnly) {
      return "Inside";
    } else {
      return "Outside";
    }
  }

  getPaperName(paperCode: string) {
    if (paperCode === "k" || paperCode === "k2") {
      return "Kraft";
    } else if (paperCode === "kw" || paperCode === "kw2") {
      return "Standard White";
    } else {
      return "Premium White";
    }
  }

  deleteFile(side: string) {
    this.deletingFile = true;
    this.containerService
      .deleteFile(this.state().uploadDetails.ContainerId, "design/uploaded_" + side + ".pdf")
      .then((_response: any) => {
        if (side === "inside") {
          this.insideFileUploaded = false;
          this.insideImageSrc = "";
        } else {
          this.outsideFileUploaded = false;
          this.outsideImageSrc = "";
        }
        this.deletingFile = false;
      })
      .catch(() => {
        this.insideFileUploaded = true;
        this.uploadingFile = false;
        this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  setActiveButton(buttonName: string) {
    this.activeButton = buttonName;
  }

  addToCart() {
    let resolveObj = {};
    resolveObj = this.prepareOrderDesignPromise(resolveObj);
    this.createOrderDesignPromise(resolveObj)
      .then((response) => {
        // console.log("we now have: ", response);
        response.quantity = this.selectedPrice.quantity;
        this.addCartItem(response);
      })
      .catch(() => {
        this.insideFileUploaded = true;
        this.uploadingFile = false;
        this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  prepareOrderDesignPromise(resolveObj: any) {
    if (this.selectedPrice) {
      this.stateSvc.updateState({ quantity: this.selectedPrice.quantity });
    }
    resolveObj.outsideCanvasJSON = "";
    resolveObj.insideCanvasJSON = "";
    resolveObj.twoSided = this.state().uploadDetails.twoSided;
    resolveObj.containerName = this.state().uploadDetails.ContainerId;
    resolveObj.boxCode = this.state().uploadDetails.Code;
    resolveObj.dimensions = this.state().uploadDetails.boxDimensions;
    resolveObj.boardType = this.state().uploadDetails.Code;
    resolveObj.boardStrength = this.state().uploadDetails.BoardStrength;
    if (this.state().uploadDetails.Subcategory === "SNAP2SF") {
      resolveObj.options = "superseal";
    } else if (this.state().uploadDetails.Subcategory === "SNAP2RSF") {
      resolveObj.options = "superseal";
    } else {
      resolveObj.options = "";
    }
    resolveObj.kraftType = this.state().uploadDetails.kraftType;
    resolveObj.secondaryKraftType = this.state().uploadDetails.secondaryKraftType;
    resolveObj.preset = this.state().uploadDetails.preset;
    resolveObj.gloss = this.state().uploadDetails.gloss;
    resolveObj.luxe = this.state().uploadDetails.luxe;
    resolveObj.quantity = this.state().quantity;
    resolveObj.insideOnly = this.state().uploadDetails.insideOnly;
    resolveObj.filename = new Date().getTime();

    return resolveObj;
  }

  createOrderDesignPromise(resolveObj: any) {
    const design = {
      customerId: resolveObj.customerID,
      container: resolveObj.containerName,
      type: "BASIC",
      outside: resolveObj.outsideCanvasJSON,
      inside: resolveObj.insideCanvasJSON,
      name: "Auto Saved",
      dimensions: resolveObj.dimensions,
      boardType: resolveObj.boardType,
      boardStrength: resolveObj.boardStrength,
      kraftType: resolveObj.kraftType,
      secondaryKraftType: resolveObj.secondaryKraftType,
      gloss: resolveObj.gloss,
      luxe: resolveObj.luxe,
      twoSided: resolveObj.twoSided,
      preset: resolveObj.preset,
      options: resolveObj.options,
      filename: resolveObj.filename,
    };
    return new Promise<any>((resolve) => {
      this.projectService
        .createOrderItem(design)
        .then((response: { container: any; type: any; preset: any }) => {
          resolveObj.design = response;
          resolveObj.containerName = response.container;
          resolveObj.type = response.type;
          resolveObj.preset = response.preset;

          if (this.isPaddedMailer) {
            this.checkoutService
              .createPaddedMailer(
                resolveObj.containerName,
                resolveObj.boxCode,
                resolveObj.boardStrength,
                resolveObj.kraftType,
                resolveObj.filename,
                this.length,
                this.width,
                this.depth,
                resolveObj.preset,
                false,
                resolveObj.quantity
              )
              .then((paddedMailer: any) => {
                resolveObj.paddedMailer = paddedMailer;
                resolve(resolveObj);
              });
          } else {
            this.checkoutService
              .createBox(
                resolveObj.containerName,
                resolveObj.boxCode,
                resolveObj.boardStrength,
                resolveObj.kraftType,
                resolveObj.secondaryKraftType,
                resolveObj.gloss,
                resolveObj.luxe,
                resolveObj.filename,
                this.length,
                this.width,
                this.depth,
                resolveObj.preset,
                resolveObj.twoSided,
                resolveObj.insideOnly,
                false,
                resolveObj.options,
                resolveObj.quantity
              )
              .then((box: any) => {
                resolveObj.box = box;
                resolve(resolveObj);
              });
          }
        })
        .catch(() => {
          this.insideFileUploaded = true;
          this.uploadingFile = false;
          this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    });
  }

  async addCartItem(resolveObj: { containerName: any; paddedMailer: { quantity: any }; box: { quantity: any } }) {
    let lineItem = {};
    if (this.isPaddedMailer) {
      resolveObj.paddedMailer.quantity = this.selectedPrice.quantity;
      lineItem = this.checkoutService.createPaddedMailerLineItem(
        resolveObj.containerName,
        this.boxData,
        resolveObj.paddedMailer
      );
    } else {
      resolveObj.box.quantity = this.selectedPrice.quantity;
      lineItem = this.checkoutService.createLineItem(resolveObj.containerName, this.boxData, resolveObj.box);
    }

    this.checkoutService
      .addToCart(lineItem)
      .then((payload: any) => {
        this.cartUrl.set(payload.url);
        this.cxmlurlencoded.set(payload.content);
        setTimeout(() => {
          this.addToCartForm.nativeElement.submit();
        });
        // this.router.navigate(["/cart"]);
      })
      .catch(() => {
        this.insideFileUploaded = true;
        this.uploadingFile = false;
        this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  getWholeNumber(fraction: any) {
    const temp = String(fraction).split(".", 2);
    return parseFloat(temp[0]);
  }

  convertDecimalToFraction(num: any) {
    return Fractions.convertDecimalToFraction(num);
  }

  listPrices() {
    let paperType;
    if (this.state().uploadDetails.kraftType === "pkw" || this.state().uploadDetails.secondaryKraftType === "pkw") {
      paperType = "pkw";
      // } else if (this.productDetails.categoryCode === 'Event Boxes') {
      //   this.productDetails.paperTypeId = this.getPaperTypeID(this.productDetails.outerMaterial);
    } else if (this.state().uploadDetails.kraftType === "kw" && this.state().uploadDetails.secondaryKraftType === "k") {
      paperType = "kw";
    } else if (this.state().uploadDetails.kraftType === "k" && this.state().uploadDetails.secondaryKraftType === "kw") {
      paperType = "kw";
    } else if (this.state().uploadDetails.kraftType === "k" && this.state().uploadDetails.secondaryKraftType === "k") {
      paperType = "k";
    } else {
      paperType = "kw";
    }
    this.prices = [];

    this.quantities = [];
    if (this.state().uploadDetails.luxe === "false") {
      this.standardQuantities.forEach((quantity) => {
        this.quantities.push(quantity);
      });
    } else {
      this.luxeQuantities.forEach((quantity) => {
        this.quantities.push(quantity);
      });
    }

    this.boxService
      .getPrice(
        this.state().uploadDetails.Code,
        this.state().uploadDetails.BoardStrength,
        paperType,
        this.length,
        this.width,
        this.depth,
        this.state().uploadDetails.quantity,
        this.state().uploadDetails.twoSided,
        this.state().uploadDetails.luxe,
        this.state().uploadDetails.gloss,
        false,
        this.state().uploadDetails.options,
        false
      )
      .then((priceResponse: { price: any }) => {
        this.selectedPrice = priceResponse.price;
        console.log("The select price is: ", this.selectedPrice);
      })
      .catch(() => {
        this.insideFileUploaded = false;
        this.uploadingFile = false;
        this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    this.boxService
      .listPrices(
        this.state().uploadDetails.Code,
        this.state().uploadDetails.BoardStrength,
        paperType,
        this.length,
        this.width,
        this.depth,
        this.quantities,
        this.state().uploadDetails.twoSided,
        this.state().uploadDetails.luxe,
        false,
        this.state().uploadDetails.options,
        false
      )
      .then(
        (priceData: { prices: any }) => {
          this.prices = priceData.prices;
        },
        () => {
          this.alertSvc.showError({ msg: "Service Unavailable - Unable to load prices" });
          this.prices = [];
        }
      );
  }

  listPaddedMailerPrices() {
    let paperType;
    if (this.state().uploadDetails.kraftType === "kw" && this.state().uploadDetails.secondaryKraftType === "k") {
      paperType = "kw";
    } else if (this.state().uploadDetails.kraftType === "k" && this.state().uploadDetails.secondaryKraftType === "kw") {
      paperType = "kw";
    } else if (this.state().uploadDetails.kraftType === "k" && this.state().uploadDetails.secondaryKraftType === "k") {
      paperType = "k";
    } else {
      paperType = "kw";
    }
    this.prices = [];

    switch (this.state().uploadDetails.Code) {
      case "RPM00Z":
        this.quantities = [1, 2, 10, 20, 100];
        break;
      case "RPM02Z":
      case "RPM05Z":
        this.quantities = [1, 5, 20, 50, 100, 200];
        break;
      case "RPM06Z":
        this.quantities = [1, 10, 50, 150, 250, 500];
        break;
    }

    this.boxService
      .getPaddedMailerPrice(
        this.state().uploadDetails.Code,
        paperType === "kw" ? "white" : "kraft",
        this.state().uploadDetails.quantity,
        false
      )
      .then((priceResponse: any) => {
        this.selectedPrice = priceResponse.price;
        console.log("The select price is: ", this.selectedPrice);
      })
      .catch(() => {
        this.insideFileUploaded = false;
        this.uploadingFile = false;
        this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    this.boxService
      .listPaddedMailerPrices(
        this.state().uploadDetails.Code,
        paperType === "kw" ? "white" : "kraft",
        this.quantities,
        false
      )
      .then(
        (priceData: any) => {
          this.prices = priceData.prices;
          console.log(priceData);
        },
        () => {
          this.alertSvc.showError({ msg: "Service Unavailable - Unable to load prices" });
          this.prices = [];
        }
      );
  }

  showModal(template: TemplateRef<any>) {
    this.modal = this.bsModalService.show(template);
  }

  hideModal() {
    this.modal?.hide();
  }

  getPrice() {
    let paperType;
    if (this.state().uploadDetails.kraftType === "pkw" || this.state().uploadDetails.secondaryKraftType === "pkw") {
      paperType = "pkw";
      // } else if (this.productDetails.categoryCode === 'Event Boxes') {
      //   this.productDetails.paperTypeId = this.getPaperTypeID(this.productDetails.outerMaterial);
    } else if (this.state().uploadDetails.kraftType === "kw" && this.state().uploadDetails.secondaryKraftType === "k") {
      paperType = "kw";
    } else if (this.state().uploadDetails.kraftType === "k" && this.state().uploadDetails.secondaryKraftType === "kw") {
      paperType = "kw";
    } else if (this.state().uploadDetails.kraftType === "k" && this.state().uploadDetails.secondaryKraftType === "k") {
      paperType = "k";
    } else {
      paperType = "kw";
    }
    this.customQuantityEntered = true;
    if (this.isPaddedMailer) {
      this.boxService
        .getPaddedMailerPrice(this.state().uploadDetails.Code, paperType, this.state().quantity, false)
        .then((priceResponse: any) => {
          this.selectedPrice = priceResponse.price;
          this.pageLoading = false;
          this.enteredCustomPrice =
            this.state().quantity +
            " case(s) of " +
            this.selectedPrice.unitsPerCase +
            " ($" +
            this.selectedPrice.pricePerUnit?.toFixed(2) +
            ") / Mailer";
        })
        .catch(() => {
          this.insideFileUploaded = true;
          this.uploadingFile = false;
          this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    } else {
      this.boxService
        .getPrice(
          this.state().uploadDetails.Code,
          this.state().uploadDetails.BoardStrength,
          paperType,
          this.length,
          this.width,
          this.depth,
          this.state().quantity,
          this.state().uploadDetails.twoSided,
          this.state().uploadDetails.luxe,
          this.state().uploadDetails.gloss,
          false,
          this.state().uploadDetails.options,
          false
        )
        .then((priceResponse: { price: any }) => {
          this.selectedPrice = priceResponse.price;
          this.pageLoading = false;
          this.enteredCustomPrice =
            this.state().quantity + " ($" + this.selectedPrice.pricePerUnit?.toFixed(2) + ") each";
        })
        .catch(() => {
          this.insideFileUploaded = true;
          this.uploadingFile = false;
          this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    }
  }

  updateState(target: string, value: string) {
    const tmp: any = {};
    tmp[target] = value;
    this.stateSvc.updateState(tmp);
  }

  trackBy(index: number, _item: any) {
    return index;
  }

  onRemove(_f: File) {}

  goBack() {
    if (this.state().isEdit) {
      if (this.state().referer) {
        const startUrl = this.state().referer + "/default/checkout/cart/";
        window.location.href = startUrl;
      }
    } else {
      this.router.navigate([
        "/box-options",
        this.state().categoryCode,
        this.state().subcategoryCode,
        this.state().uploadDesign,
      ]);
    }
  }
}
