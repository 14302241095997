import { AuditedSerializable, ISerializable } from './base';
import { Price, PriceDebug } from './price';

export class BoxImage extends AuditedSerializable<BoxImage> {
  code: string | undefined;
  location: string | undefined;
  default: boolean | undefined;
  hover: string | undefined;

  override deserialize(input: any): BoxImage {
    this.location = input.location;
    this.default = input.default;
    this.hover = input.hover;
    super.deserialize(input);
    return this;
  }
}

export class BoardStrength extends AuditedSerializable<BoardStrength> {
  code: string | undefined;
  name: string | undefined;
  strength: string | undefined;

  override deserialize(input: any): BoardStrength {
    super.deserialize(input);
    this.code = input.code;
    this.name = input.name;
    this.strength = input.strength;
    return this;
  }
}

export class BoardType extends AuditedSerializable<BoardType> {
  code!: string;
  name!: string;
  flute: string | undefined;
  thickness: number | undefined;
  boardStrengths: BoardStrength[] = [];

  override deserialize(input: any): BoardType {
    super.deserialize(input);
    this.code = input.code;
    this.name = input.name;
    this.flute = input.flute;
    this.thickness = input.thickness;
    if (input.boardStrengths) {
      this.boardStrengths = [];
      (input.boardStrengths as any[]).forEach((boardStrength) => {
        this.boardStrengths.push(
          new BoardStrength().deserialize(boardStrength)
        );
      });
    }
    return this;
  }
}

export class Subcategory extends AuditedSerializable<Subcategory> {
  code: string | undefined;
  name: string | undefined;
  secondaryName: string | undefined;
  allowCustomSizes: boolean | undefined;
  data: any[] = [];
  displayPremiumWhite: boolean | undefined;
  displayWhite: boolean | undefined;
  displaySecondaryWhite: boolean | undefined;
  displayKraft: boolean | undefined;
  displaySecondaryKraft: boolean | undefined;
  displayTwoSided: boolean | undefined;
  displayLuxe: boolean | undefined;
  displayNoprint: boolean | undefined;
  displayStandard: boolean | undefined;
  allowCustomSample: boolean | undefined;
  hasVariants: boolean | undefined;
  images: BoxImage[] = [];
  boardTypes: BoardType[] = [];
  category: Category | undefined;

  presets: Preset[] | undefined; // Not part of initial payload

  get isDHC() {
    return this.code === 'DHC';
  }

  override deserialize(input: any): Subcategory {
    super.deserialize(input);
    this.code = input.code;
    this.name = input.name;
    this.secondaryName = input.secondaryName;
    this.data = input.data;
    this.allowCustomSizes = input.allowCustomSizes;
    this.displayPremiumWhite = input.displayPremiumWhite;
    this.displayWhite = input.displayWhite;
    this.displaySecondaryWhite = input.displaySecondaryWhite;
    this.displayKraft = input.displayKraft;
    this.displaySecondaryKraft = input.displaySecondaryKraft;
    this.displayTwoSided = input.displayTwoSided;
    this.displayLuxe = input.displayLuxe;
    this.displayNoprint = input.displayNoprint;
    this.displayStandard = input.displayStandard;
    this.allowCustomSample = input.allowCustomSample;
    this.hasVariants = input.hasVariants;
    if (input.images) {
      (input.images as any[]).forEach((image) => {
        this.images.push(new BoxImage().deserialize(image));
      });
    }
    if (input.boardTypes) {
      (input.boardTypes as any[]).forEach((boardType) => {
        this.boardTypes.push(new BoardType().deserialize(boardType));
      });
    }
    if (input.category) {
      this.category = new Category().deserialize(input.category);
    }
    return this;
  }
}

export class Category extends AuditedSerializable<Category> {
  code: string | undefined;
  name: string | undefined;
  description: string | undefined;
  includeInProducts: boolean | undefined;
  imageLocation: string | undefined;
  subcategories: Subcategory[] = [];

  override deserialize(input: any): Category {
    super.deserialize(input);
    // console.log(input);
    this.code = input.code;
    this.name = input.name;
    this.description = input.description;
    this.includeInProducts = input.includeInProducts;
    this.imageLocation = input.imageLocation;
    if (input.subcategories) {
      this.subcategories = [];
      (input.subcategories as any[]).forEach((subcategory) => {
        this.subcategories.push(new Subcategory().deserialize(subcategory));
      });
    }
    return this;
  }
}

export class BoxType extends AuditedSerializable<BoxType> {
  name: string | undefined;
  description: string | undefined;
  code: string | undefined;
  aeCode: string | undefined;
  boardTypeCode: string | undefined;
  widthScore: number | undefined;
  lengthScore: number | undefined;
  tuck: number | undefined;
  flap: number | undefined;
  minimumOrder: number | undefined;
  luxeMinimumOrder: number | undefined;
  maxQuantity: number | undefined;
  boardType: BoardType | undefined;
  subcategory: Subcategory | undefined;
  increment: number | undefined;

  override deserialize(input: any): BoxType {
    super.deserialize(input);
    this.name = input.name;
    this.description = input.description;
    this.code = input.code;
    this.aeCode = input.aeCode;
    this.boardTypeCode = input.boardTypeCode;
    this.widthScore = input.widthScore;
    this.lengthScore = input.lengthScore;
    this.tuck = input.tuck;
    this.flap = input.flap;
    this.minimumOrder = input.minimumOrder;
    this.luxeMinimumOrder = input.luxeMinimumOrder;
    this.maxQuantity = input.maxQuantity;
    this.increment = input.increment;
    if (input.boardType) {
      this.boardType = new BoardType().deserialize(input.boardType);
    }
    if (input.subcategory) {
      this.subcategory = new Subcategory().deserialize(input.subcategory);
    }
    return this;
  }
}

export class PaperType implements ISerializable<PaperType> {
  id: string | undefined;
  name: string | undefined;
  printTypes: PrintType[] = [];
  finishes: string[] = [];
  secondary: string[] = [];

  get luxeAllowed() {
    return this.isAllowed(new PrintType().deserialize({ id: 'luxe' }));
  }

  get glossAllowed() {
    return this.finishes.indexOf('Gloss') !== -1;
  }

  isAllowed(printType: PrintType) {
    const pt = this.printTypes.find((value) => {
      return value.id === printType.id;
    });
    // console.log('printType', printType, pt);
    return pt !== undefined && pt.allowed;
  }

  deserialize(input: any): PaperType {
    this.id = input.id;
    this.name = input.name;
    this.printTypes = input.printTypes || [];
    this.finishes = input.finishes || [];
    this.secondary = input.seconary || [];
    return this;
  }
}

export class PrintType implements ISerializable<PrintType> {
  id: string | undefined;
  name: string | undefined;
  allowed: boolean | undefined;

  deserialize(input: any): PrintType {
    this.id = input.id;
    this.name = input.name;
    this.allowed = input.allowed;
    return this;
  }
}

export class Preset extends AuditedSerializable<Preset> {
  code!: string;
  label: string | undefined;
  length!: number;
  width!: number;
  depth!: number;
  type: BoxType | undefined;
  allowCustomSizes: boolean | undefined;
  displayPremiumWhite: boolean | undefined;
  displayWhite: boolean | undefined;
  displayKraft: boolean | undefined;
  displaySecondaryWhite: boolean | undefined;
  displaySecondaryKraft: boolean | undefined;
  displayTwoSided: boolean | undefined;
  displayLuxe: boolean | undefined;
  displayStandard: boolean | undefined;
  displayNoprint: boolean | undefined;
  allowCustomSample: boolean | undefined;
  hasVariants: boolean | undefined;
  boardStrengths: BoardStrength[] = [];

  override deserialize(input: any): Preset {
    super.deserialize(input);
    this.code = input.code;
    this.length = input.length;
    this.width = input.width;
    this.depth = input.depth;
    this.label = input.label
      ? input.label
      : this.length + ' x ' + this.width + ' x ' + this.depth;
    if (input.type.subcategory) {
      this.allowCustomSizes = input.type.subcategory.allowCustomSizes;
      this.displayPremiumWhite = input.type.subcategory.displayPremiumWhite;
      this.displayWhite = input.type.subcategory.displayWhite;
      this.displayKraft = input.type.subcategory.displayKraft;
      this.displaySecondaryWhite = input.type.subcategory.displaySecondaryWhite;
      this.displaySecondaryKraft = input.type.subcategory.displaySecondaryKraft;
      this.displayTwoSided = input.type.subcategory.displayTwoSided;
      this.displayLuxe = input.type.subcategory.displayLuxe;
      this.displayNoprint = input.type.subcategory.displayNoprint;
      this.displayStandard = input.type.subcategory.displayStandard;
      this.allowCustomSample = input.type.subcategory.allowCustomSample;
      this.hasVariants = input.type.subcategory.hasVariants;
    }
    if (input.type) {
      this.type = new BoxType().deserialize(input.type);
    }
    if (input.boardStrengths) {
      this.boardStrengths = [];
      (input.boardStrengths as any[]).forEach((boardStrength) => {
        this.boardStrengths.push(
          new BoardStrength().deserialize(boardStrength)
        );
      });
    }
    return this;
  }
}

export class Dimensions implements ISerializable<Dimensions> {
  length: number | undefined;
  width: number | undefined;
  depth: number | undefined;
  knockDownFlatLength: number | undefined;
  knockDownFlatWidth: number | undefined;
  knockDownFlatDepth: number | undefined;
  squareFeetTotal: number | undefined;
  blankSize: string | undefined;
  weight: number | undefined;

  deserialize(input: any): Dimensions {
    this.length = input.length;
    this.width = input.width;
    this.depth = input.depth;
    this.knockDownFlatLength = input.knockDownFlatLength;
    this.knockDownFlatWidth = input.knockDownFlatWidth;
    this.knockDownFlatDepth = input.knockDownFlatDepth;
    this.squareFeetTotal = input.squareFeetTotal;
    this.blankSize = input.blankSize;
    this.weight = input.weight;
    return this;
  }
}

export class Shipping implements ISerializable<Shipping> {
  length: number | undefined;
  width: number | undefined;
  depth: number | undefined;
  weight: number | undefined;

  deserialize(input: any): Shipping {
    this.length = input.length;
    this.width = input.width;
    this.depth = input.depth;
    this.weight = input.weight;
    return this;
  }
}



export class Violation implements ISerializable<Violation> {
  rule: string | undefined;
  message: string | undefined;
  warning: boolean | undefined;
  error: boolean | undefined;

  deserialize(input: any): Violation {
    this.rule = input.rule;
    this.message = input.message;
    this.warning = input.warning;
    this.error = input.error;
    return this;
  }
}

export class PricingResponse implements ISerializable<PricingResponse> {
  prices!: Price[];
  debug: PriceDebug | undefined;
  box!: Box;
  partition: any;
  presetMatches: Preset[] = [];
  customSizeIncrease: number = 0;
  rushMinimumQuantity: number = 0;
  rushMaximumQuantity: number = 0;

  deserialize(input: any): PricingResponse {
    if (input.debug) {
      this.debug = new PriceDebug().deserialize(input.debug);
    }
    this.customSizeIncrease = input.customSizeIncrease;
    this.rushMinimumQuantity = input.rushMinimumQuantity;
    this.rushMaximumQuantity = input.rushMaximumQuantity;
    this.prices = [];
    if (input.prices) {
      input.prices.forEach((value: any) => {
        if (value) {
          const price = new Price().deserialize(value);
          this.prices.push(price);
        }
      });
    }
    this.presetMatches = [];
    // Ryan Note - What does this do and why is it causing an allowcustomsizes error when a custom size is entered?
    if (input.presetMatches) {
      input.presetMatches.forEach((value: any) => {
        const preset = new Preset().deserialize(value);
        this.presetMatches.push(preset);
      });
    }
    if (input.box) {
      this.box = new Box().deserialize(input.box);
    }

    if (input.partition) {
      this.partition = input.partition;
    }
    return this;
  }
}

export class Material extends AuditedSerializable<Material> {
  code: string | undefined;
  paper: string | undefined;
  weightPerSquareFoot: number | undefined;
  boardStrength: BoardStrength | undefined;
  boardType: BoardType | undefined;
  premium: boolean | undefined;
  paperType: string | undefined;

  override deserialize(input: any): Material {
    super.deserialize(input);
    this.code = input.code;
    this.paper = input.paper;
    this.weightPerSquareFoot = input.weightPerSquareFoot;
    if (input.boardStrength) {
      this.boardStrength = new BoardStrength().deserialize(input.boardStrength);
    }
    if (input.boardType) {
      this.boardType = new BoardType().deserialize(input.boardType);
    }
    this.premium = input.premium;
    this.paperType = input.paperType;
    return this;
  }
}

export class Box implements ISerializable<Box> {
  preset: boolean | undefined;
  boxType: BoxType | undefined;
  dimensions: Dimensions | undefined;
  shipping: Shipping | undefined;
  price: Price | undefined;
  material: Material | undefined;
  violations!: Violation[];

  deserialize(input: any): Box {
    this.preset = input.preset;
    this.boxType = new BoxType().deserialize(input.type);
    this.dimensions = input.dimensions;
    this.shipping = input.shipping;
    if (input.price) {
      this.price = new Price().deserialize(input.price);
    }
    if (input.material) {
      this.material = new Material().deserialize(input.material);
    }
    this.violations = [];
    if (input.constraintViolations) {
      input.constraintViolations.forEach((value: any) => {
        const violation = new Violation().deserialize(value);
        this.violations.push(violation);
      });
    }
    return this;
  }
}

export class ShippedBox {
  name: string | undefined;
  length: number | undefined;
  width: number | undefined;
  depth: number | undefined;
  weight: number | undefined;
  quantity: number | undefined;
}

export class SelectablePreset {
  label!: string;
  code!: string;
  presets!: Preset[];

  static category(name: string, code: string) {
    const box = new SelectablePreset();
    box.label = name.replace(/<sup>TM<\/sup>/i, '(TM)'); // TODO: do this in a better way
    box.code = code;
    box.presets = [];
    return box;
  }
}

export class Variant extends AuditedSerializable<Variant> {
  name: string | undefined;
  code: string | undefined;
  subcategory: Subcategory | undefined;

  override deserialize(input: any): Variant {
    super.deserialize(input);
    this.id = input.id;
    this.name = input.name;
    this.code = input.code;
    this.subcategory = input.subcategory;
    return this;
  }
}

  export class BoxPricingResponse implements ISerializable<BoxPricingResponse> {
    prices: BoxPrice[] = [];
    debug: PriceDebug | undefined;
    box: Box | undefined;

    deserialize(input: any): BoxPricingResponse {
        if (input.debug) {
            this.debug = new PriceDebug().deserialize(input.debug);
        }
        this.prices = [];
        if (input.prices) {
            input.prices.forEach((value: any) => {
                const price = new BoxPrice().deserialize(value);
                this.prices.push(price);
            });
        }
        if (input.box) {
            this.box = new Box().deserialize(input.box);
        }
        return this;
    }
}

export class BoxPrice implements ISerializable<BoxPrice> {
  msf: number | undefined;
  baseMsf: number | undefined;
  pricePerUnit: number | undefined;
  totalPrice: number | undefined;
  discountRate: number | undefined;
  showDiscount: boolean | undefined;
  minimumOrder: number | undefined;
  maxQuantity: number | undefined;
  quantity: number | undefined;
  type: string | undefined;
  base: string | undefined;

  deserialize(input: any): BoxPrice {
    this.msf = input.msf;
    this.baseMsf = input.baseMsf;
    this.pricePerUnit = input.pricePerUnit;
    this.totalPrice = input.totalPrice;
    this.discountRate = input.discountRate;
    this.showDiscount = input.showDiscount;
    this.minimumOrder = input.minimumOrder;
    this.maxQuantity = input.maxQuantity;
    this.quantity = input.quantity;
    this.type = input.type;
    this.base = input.base;
    return this;
  }
}

