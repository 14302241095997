import { inject, Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { NGXLogger } from "ngx-logger";
import { AuthToken } from "../../../models/src/lib/auth";
import { ENVIRONMENT } from "../../../models/src/lib/injection.tokens";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private token: AuthToken | undefined;
  protected httpClient: HttpClient = inject(HttpClient);
  protected environment: any = inject(ENVIRONMENT);
  protected logger: NGXLogger = inject(NGXLogger);
  
  constructor() {}

  authorize(loginData: {
    username: string | number | boolean;
    password: string | number | boolean;
  }): Promise<AuthToken> {
    return new Promise<AuthToken>((resolve, reject) => {
      this.clearToken();

      const params = new HttpParams()
        .append("grant_type", "password")
        .append("username", loginData.username)
        .append("password", loginData.password);

      this.httpClient
        .post(`${this.environment.apiHost}/user/oauth/token`, params, {
          headers: new HttpHeaders({
            "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
          }),
        })
        .subscribe(
          (res: any) => {
            this.setToken(new AuthToken().deserialize(res));
            this.checkToken().then(
              (authenticated) => {
                if (authenticated) {
                  resolve(this.token!);
                } else {
                  reject({
                    error: "invalid_grant",
                    error_description: "Invalid username or password",
                  });
                }
              },
              (response) => {
                this.logger.debug(response.error);
                reject(response.error);
              }
            );
          },
          (response) => {
            this.logger.debug(response.error);
            reject(response.error);
          }
        );
    });
  }

  getToken(): AuthToken | undefined {
    if (this.token && this.token.expiresIn && this.token.expiresIn > 0) {
      return this.token;
    } else {
      this.clearToken();
      return this.token;
    }
  }

  protected setToken(token: AuthToken | undefined): void {
    this.token = token;
    sessionStorage.setItem("token", JSON.stringify(token));
  }

  checkToken(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.logger.info("Checking user token");
      if (!this.token) {
        resolve(false);
      }
      this.httpClient
        .get(`${this.environment.apiHost}/user/oauth/check_token`, {
          params: {
            token: this.token!.accessToken!,
          },
        })
        .subscribe(
          (res: any) => {
            this.logger.trace("Check token response", res);
            const authorities: any[] = [];
            (res.authorities as any[]).forEach((role) => {
              authorities.push(role);
            });
            this.setToken({ ...this.token, authorities } as AuthToken);
            resolve(true);
          },
          (error) => {
            this.clearToken;
            this.logger.trace(error.message || error);
            reject(error);
          }
        );
    });
  }

  renewToken(): Promise<AuthToken> {
    return new Promise<AuthToken>((resolve, reject) => {
      this.logger.info("Refreshing user token");
      if (!this.token) {
        reject({ message: "No token to refresh" });
      }
      this.setToken({ ...this.token, accessToken: undefined } as AuthToken);
      const headers = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
      const body = "refresh_token=" + this.token!.refreshToken + "&grant_type=refresh_token";

      this.httpClient.post(`${this.environment.apiHost}/user/oauth/token`, body, { headers }).subscribe(
        (res: any) => {
          this.logger.trace("Check token response", res);
          this.setToken({ ...this.token, accessToken: res.access_token } as AuthToken);

          this.logger.trace("new token", this.token);
          resolve(this.token!);
        },
        (error) => {
          this.clearToken;
          this.logger.trace(error.message || error);
          reject(error);
        }
      );
    });
  }

  clearToken(): void {
    this.setToken(undefined);
  }

  logout(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.httpClient.post(`${this.environment.apiHost}/user/logout`, null).subscribe(
        () => {
          this.logger.info("User logged out");
          this.clearToken();
          resolve(true);
        },
        (error) => {
          this.logger.debug("Unable to log out user", error);
          reject(error);
        }
      );
    });
  }

  validate(authToken: AuthToken | undefined, roles: string[], name: string): boolean {
    if (!authToken && !this.token) {
        return false;
    }

    let passed = true;
    roles.forEach((role) => {
      let inverted = false;
      if (role.startsWith("!")) {
        inverted = true;
        role = role.substr(1);
      }

      const indexOfRole = authToken?.authorities.indexOf("ROLE_" + role)??0;
      if (!inverted && indexOfRole > -1) {
        this.logger.trace("inverted", inverted);
        if (name) {
          this.logger.trace("User has '" + role + "' role, access granted to named resource '" + name + "'");
        } else {
          this.logger.trace("User has '" + role + "' role");
        }
        // this.logger.debug('User has \'' + role + '\' role');
        passed = passed && true;
      } else if (inverted && indexOfRole === -1) {
        this.logger.trace("inverted", inverted);
        if (name) {
          this.logger.trace(
            "User does not have excluded '" + role + "' role, access granted named resource '" + name + "'"
          );
        } else {
          this.logger.trace("User does not have excluded '" + role + "' role");
        }
        passed = passed && true;
      } else {
        this.logger.trace("inverted", inverted);
        if (name) {
          this.logger.trace("User does NOT have access");
        }
        passed = false;
      }
    });

    return passed;
  }
}
