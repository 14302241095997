import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from "@angular/router";
import { FXAuthService } from "./services/fx-auth.service";
import { inject } from "@angular/core";
import { ClientSessionStateService } from "./services/sessionState.service";
import { NGXLogger } from "ngx-logger";
import { ConfirmationModalComponent } from "./features/confirmation-modal/confirmation-modal.component";
import { ERROR_BODY, ERROR_TITLE } from "../globals";
import { BsModalService } from "ngx-bootstrap/modal";
import { AUTH_SVC } from "../../../libs/models/src/lib/injection.tokens";
import { AlertService } from "../../../libs/services/src/lib/alert.service";

export function requireSessionID(): CanActivateFn {
  return async (route: ActivatedRouteSnapshot) => {
    const authService: FXAuthService = inject(AUTH_SVC) as FXAuthService;
    const stateSvc = inject(ClientSessionStateService);
    const logger = inject(NGXLogger);
    const modalSvc = inject(BsModalService);
    const alertSvc = inject(AlertService);

    const sessionKey = route.queryParams["sessionID"];
    if (sessionKey) {
      stateSvc.updateState({ sessionKey: sessionKey });
    }

    if (stateSvc.getStateValue("sessionKey")) {

      try {
        const res = await authService.getAuthData().then((res: any) => {
          if (res.status === 401) {
            return res;
          }
          stateSvc.updateState({ authSessionData: res });
          return res;
        });
        if (res.status === 401) {
          modalSvc.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
          alertSvc.showError({ msg: "User Unauthorized" });
          return false;
        }
      } catch (error) {
        logger.error("Error getting auth data", error);
        modalSvc.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
        return false;
      }

      try {
        await authService.getCurrentUser();
        return true;
      } catch (error) {
        logger.error("Error getting current user", error);
        modalSvc.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
        return false;
      }
    } else {
      logger.error("Missing sessionID");
      modalSvc.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      stateSvc.clearState();
      return false;
    }
  };
}

export function requireLogin(): CanActivateFn {
  return async (route: ActivatedRouteSnapshot,  state:RouterStateSnapshot) => {

    const authService: FXAuthService = inject(AUTH_SVC) as FXAuthService;
    await requireSessionID()(route, state);
    return !!authService.getUser();
  };
}

export function refreshCustomerState(): CanActivateFn {
  return () => {
    inject(ClientSessionStateService).refreshState();
    return true;
  };
}
