import { ISerializable } from "./base";

export class AuthToken implements ISerializable<AuthToken> {

    accessToken: string | undefined;
    tokenType: string | undefined;
    refreshToken: string | undefined;
    expiresIn: number | undefined;
    scope: string | undefined;
    authorities: string[] = [];

    deserialize(input: any): AuthToken {
        this.accessToken = input.access_token;
        this.tokenType = input.token_type;
        this.refreshToken = input.refresh_token;
        this.expiresIn = input.expires_in;
        this.scope = input.scope;
        return this;
    }

}
