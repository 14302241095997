
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-page-content-wrapper',
    standalone: true,
    imports: [],
    templateUrl: './page-content-wrapper.component.html',
    styleUrl: './page-content-wrapper.component.scss'
})
export class PageContentWrapperComponent {
  @Input() goBack: Function = () => {};

}
