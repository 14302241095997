import {
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Subject,
} from "rxjs";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { environment } from "../../environments/environment";
import { Preset } from "../../../../libs/models/src/lib/box";
import { CookieService } from "./cookie.service";
import { NGXLogger } from "ngx-logger";

@Injectable({
  providedIn: "root",
})
export class CheckoutService {
  cartChanged = new Subject<any>();

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private sanitizier: DomSanitizer,
    private logger: NGXLogger
  ) {}

  createBox(
    containerName: any,
    type: any,
    boardStrength: any,
    kraftType: any,
    secondaryKraftType: any,
    gloss: any,
    luxe: any,
    aeFilename: any,
    length: any,
    width: any,
    depth: any,
    preset: any,
    twoSided: any,
    insideOnly: any,
    rush: any,
    options: any,
    quantity: any
  ) {
    return new Promise<any>((resolve, _reject) => {
      const box = {
        containerName,
        type,
        boardStrength,
        kraftType,
        secondaryKraftType,
        gloss,
        luxe,
        twoSided,
        insideOnly,
        options,
        aeFilename,
        length,
        width,
        depth,
        quantity,
        preset,
        rush,
      };
      resolve(box);
    });
  }

  createPaddedMailer(
    containerName: any,
    type: any,
    boardStrength: any,
    kraftType: any,
    aeFilename: any,
    length: any,
    width: any,
    depth: any,
    preset: any,
    rush: any,
    quantity: any
  ) {
    return new Promise<any>((resolve, reject) => {
      const paddedMailer = {
        containerName,
        type,
        boardStrength,
        kraftType,
        aeFilename,
        length,
        width,
        depth,
        quantity,
        preset,
        rush,
      };
      resolve(paddedMailer);
    });
  }

  createNoprint(
    containerName: any,
    type: any,
    boardStrength: any,
    kraftType: any,
    secondaryKraftType: any,
    gloss: any,
    luxe: any,
    aeFilename: any,
    length: any,
    width: any,
    depth: any,
    preset: any,
    twoSided: any,
    insideOnly: any,
    rush: any,
    options: any,
    quantity: any,
    noprint: any
  ) {
    return new Promise<any>((resolve, reject) => {
      const box = {
        containerName,
        type,
        boardStrength,
        kraftType,
        secondaryKraftType,
        gloss,
        luxe,
        twoSided,
        insideOnly,
        options,
        aeFilename,
        length,
        width,
        depth,
        quantity,
        preset,
        rush,
        noprint,
      };
      resolve(box);
    });
  }

  createPartition(
    paperType: any,
    boardType: any,
    length: any,
    width: any,
    depth: any,
    lengthCells: any,
    widthCells: any,
    lengthSlots: any,
    widthSlots: any,
    quantity: any,
    rush: any
  ) {
    return new Promise<any>((resolve, reject) => {
      const partition = {
        // type,
        paperType,
        boardType,
        length,
        width,
        depth,
        lengthCells,
        widthCells,
        lengthSlots,
        widthSlots,
        quantity,
        rush,
      };
      resolve(partition);
    });
  }

  createTape(
    containerName: string,
    paperType: any,
    length: any,
    quantity: any,
    rush: any
  ) {
    return new Promise<any>((resolve) => {
      const tape = {
        containerName,
        paperType,
        length,
        quantity,
        rush,
      };
      resolve(tape);
    });
  }

  createTissue(
    containerName: string,
    paperType: any,
    weight: number,
    quantity: number,
    rush: boolean,
    length: number,
    width: number
  ) {
    return new Promise<any>((resolve, reject) => {
      const tissue = {
        containerName,
        paperType: "white",
        weight,
        quantity,
        rush,
        length,
        width,
      };

      resolve(tissue);
    });
  }

  createCrinkle(quantity: number, color: string) {
    return new Promise<any>((resolve, reject) => {
      const crinkle = {
        quantity,
        color,
      };

      resolve(crinkle);
    });
  }

  createLabel(
    containerName: string,
    paperType: any,
    shape: any,
    length: any,
    width: any,
    depth: any,
    rush: any,
    quantity: any
  ) {
    return new Promise<any>((resolve, reject) => {
      const label = {
        containerName,
        paperType,
        shape,
        length,
        width,
        depth,
        rush,
        quantity,
      };
      resolve(label);
    });
  }

  createLineItem(_containerName: string, _type: any, box: any): any {
    return {
      quantity: box.quantity,
      box,
    };
  }

  createPaddedMailerLineItem(
    containerName: any,
    type: any,
    paddedMailer: { quantity: any; preset?: Preset | undefined; subcategory?: string }
  ): any {
    return {
      quantity: paddedMailer.quantity,
      type,
      paddedMailer,
      preset: paddedMailer.preset,
      subcategory: paddedMailer.subcategory,
      containerName,
    };
  }

  createTissueLineItem(
    containerName: any,
    type: any,
    tissue: { quantity: any; preset: any; subcategory: any }
  ): any {
    return {
      quantity: tissue.quantity,
      type,
      tissue,
      preset: tissue.preset,
      subcategory: tissue.subcategory,
      containerName,
    };
  }

  createTapeLineItem(
    containerName: any,
    type: any,
    tape: { quantity: any; preset: any; subcategory: any }
  ): any {
    return {
      quantity: tape.quantity,
      type,
      tape,
      preset: tape.preset,
      subcategory: tape.subcategory,
      containerName,
    };
  }

  createLabelLineItem(
    containerName: any,
    type: any,
    label: { quantity: any; preset: any; subcategory: any }
  ): any {
    return {
      quantity: label.quantity,
      type,
      label,
      preset: label.preset,
      subcategory: label.subcategory,
      containerName,
    };
  }

  createPartitionLineItem(
    partition: { quantity: any; preset: any; subcategory: any },
    type: any
  ): any {
    return {
      quantity: partition.quantity,
      type,
      partition,
      preset: partition.preset,
      subcategory: partition.subcategory,
    };
  }

  createCrinkleLineItem(
    crinkle: { quantity: any; preset: any; subcategory: any },
    type: any
  ): any {
    return {
      quantity: crinkle.quantity,
      type,
      crinkle,
      preset: crinkle.preset,
      subcategory: crinkle.subcategory,
    };
  }

  updateLineItem(lineItem: any) {
    const headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    const payload = JSON.stringify([lineItem]);
    const checkout = this.readCheckoutCookie();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .put(
          `${environment.apiHost}/ecom/checkout/${checkout.id}/lineitems`,
          payload,
          headers
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            if (error.exception === "feign.RetryableException") {
              this.updateLineItem(lineItem);
            }
            reject("Unable to add lineitem: " + JSON.stringify(error));
          }
        );
    });
  }

  addToCart(lineItem: any) {
    const checkout = this.readCheckoutCookie();
    if (!checkout) {
      return new Promise<any>((resolve, reject) => {
        this.createCheckout(lineItem)
          .then((createdCheckout) => {
            this.logger.info("Checkout is: ", createdCheckout);
            resolve(createdCheckout);
          })
          .catch((error) => {
            if (error.exception === "feign.RetryableException") {
              this.logger.error("Try again please!");
            }
            this.logger.error("Unable to create checkout: ", error);  
          });
      });
    } else {
      return new Promise<any>((resolve, reject) => {
        this.addLineItem(lineItem).then(
          (response) => {
            response = this.updateCheckoutData(response);
            this.createCheckoutCookie(response);
            resolve(response);
          },
          (error) => {
            reject("Unable to add line items: " + JSON.stringify(error));
          }
        );
      });
    }
  }

  // removeFromCart(lineItem: { id: any }) {
  //   const checkout = this.readCheckoutCookie();
  //   return new Promise<any>((resolve, reject) => {
  //     this.httpClient
  //       .delete(
  //         `${environment.apiHost}/ecom/checkout/${checkout.id}/lineitems/${lineItem.id}`
  //       )
  //       .subscribe(
  //         (response) => {
  //           response = this.updateCheckoutData(response);
  //           this.createCheckoutCookie(response);
  //           resolve(response);
  //         },
  //         (error) => {
  //           reject(error);
  //         }
  //       );
  //   });
  // }

  // clear() {
  //   this.cookieService.delete("checkout");
  // }

  createCheckout(lineItem: any) {
    const url = `${environment.apiHost}/ecom/checkout/cart`;
    const payload = JSON.stringify(lineItem);
    const headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(url, payload, headers).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  readCheckoutCookie() {
    const encodedData = this.cookieService.read("checkout");
    if (encodedData) {
      const checkout = JSON.parse(atob(encodedData));
      return checkout;
    }
    return undefined;
  }

  createCheckoutCookie(data: any) {
    const checkout = {
      id: data.id,
      itemCount: data.itemCount,
      subtotal: data.total,
      url: data.webUrl,
    };
    const cookie = JSON.stringify(checkout);
    this.cookieService.create("checkout", btoa(cookie), 3);
    return checkout;
  }

  updateCheckoutData(checkout: any) {
    checkout.lineItems.forEach(
      (item: { titleAsHtml: SafeHtml; title: string }) => {
        item.titleAsHtml = this.sanitizier.bypassSecurityTrustHtml(item.title);
      }
    );
    return checkout;
  }

  addLineItem(lineItem: any) {
    const payload = JSON.stringify([lineItem]);
    const checkout = this.readCheckoutCookie();
    const headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json;charset=UTF-8",
      }),
    };

    const url = `${environment.apiHost}/ecom/checkout/${checkout.id}/lineitems`;
    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(url, payload, headers).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
