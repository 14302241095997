import { AuditedSerializable, ISerializable } from "./base";
import { User } from "./user";
import { ProductType, Subcategory } from "./category";

import moment from "moment";
import Mediatype from "../../../utils/src/lib/mediatype";
import Size from "../../../utils/src/lib/size";

export class Project extends AuditedSerializable<Project> {
  get online() {
    return this.type === "BASIC";
  }

  get offline() {
    return this.type === "ADVANCED";
  }

  static EMPTY: Project = new Project();
  name: string | undefined;
  type: string | undefined; // Basic or Advanced
  productType: string | undefined;
  preset: string | undefined;
  boardStrength: string | undefined;
  boardType: string | undefined;
  boxType: ProductType | undefined;
  kraftType: string | undefined;
  secondaryKraftType: string | undefined;
  container: string | undefined;
  content: string | undefined;
  customerId: string | undefined;
  dimensions: string | undefined;
  gloss: boolean | undefined;
  luxe: boolean | undefined;
  insideOnly: boolean | undefined;
  twoSided: boolean | undefined;
  filename: string | undefined;
  user: User | undefined;
  userId: number | undefined;
  screenshotData: string | undefined;
  screenshotUrl: string | undefined;
  created: Date | undefined;
  updated: Date | undefined;
  proofApproved: Date | undefined;
  proofCreated: Date | undefined;
  proofUrl: string | undefined;
  proofNeeded: boolean | undefined;
  workableId: string | undefined;

  override deserialize(input: any): Project {
    super.deserialize(input);
    this.name = input.name;
    this.type = input.type;
    this.productType = input.productType;
    this.preset = input.preset;
    this.boardStrength = input.boardStrength;
    this.boardType = input.boardType;
    this.kraftType = input.kraftType;
    this.secondaryKraftType = input.secondaryKraftType;
    this.container = input.container;
    this.content = input.content;
    this.customerId = input.customerId;
    this.dimensions = input.dimensions;
    this.gloss = input.gloss;
    this.luxe = input.luxe;
    this.insideOnly = input.insideOnly;
    this.twoSided = input.twoSided;
    this.filename = input.filename;
    this.userId = input.userId;
    this.proofCreated = input.proofCreated;
    this.proofApproved = input.proofApproved;
    this.proofUrl = input.proofUrl;
    this.proofNeeded = input.proofNeeded;
    this.workableId = input.workableId;
    if (input.boxType) {
      this.boxType = new ProductType().deserialize(input.boxType);
    }
    if (input.createdTime) {
      this.created = moment(input.createdTime).toDate();
    }
    if (input.updatedTime) {
      this.updated = moment(input.updatedTime).toDate();
    }
    if (input.user) {
      this.user = new User().deserialize(input.user);
    }
    this.screenshotData = input.screenshotData;
    this.screenshotUrl = input.screenshotUrl;
    return this;
  }
}

export class DraftDesignSize implements ISerializable<DraftDesignSize> {
  id: number | undefined;

  created: Date | undefined;
  updated: Date | undefined;
  createdBy: string | undefined;
  updatedBy: string | undefined;

  published: boolean | undefined;
  screenshotUrl: string | undefined;

  deleted = false; // not in request

  project: Project | undefined;

  toggleDeleted() {
    this.deleted = !this.deleted;
  }

  deserialize(input: any): DraftDesignSize {
    this.id = input.id;
    if (input.created) {
      this.created = moment(input.created).toDate();
    }
    if (input.updated) {
      this.updated = moment(input.updated).toDate();
    }
    this.createdBy = input.createdBy;
    this.updatedBy = input.updatedBy;
    this.published = input.published;
    if (input.screenshotUrl) {
      this.screenshotUrl = input.screenshotUrl;
    }
    this.project = new Project().deserialize(input.project);
    return this;
  }
}

export class DraftDesignTag implements ISerializable<DraftDesignTag> {
  id: number | undefined;
  name: string | undefined;

  deleted: boolean | undefined;
  updated: boolean | undefined;

  deserialize(input: any): DraftDesignTag {
    this.id = input.id;
    this.name = input.name;
    return this;
  }
}

export class DraftDesignCollection implements ISerializable<DraftDesignCollection> {
  id: number | undefined;
  name: string | undefined;
  published: boolean | undefined;
  designs: number | undefined;

  deleted: boolean | undefined;

  get publishedText(): string {
    return this.published ? "Published" : "Unpublished";
  }

  deserialize(input: any): DraftDesignCollection {
    this.id = input.id;
    this.name = input.name;
    this.published = input.published;
    this.designs = input.designs;
    this.deleted = false;
    return this;
  }
}

export class DraftDesign implements ISerializable<DraftDesign> {
  id: number | undefined;
  createdBy: string | undefined;
  created: Date | undefined;
  updatedBy: string | undefined;
  updated: Date | undefined;
  name: string | undefined;
  description: string | undefined;
  displayOrder: number | undefined;
  screenshotUrl: string | undefined;
  subcategory: Subcategory | undefined;
  boardType: string | undefined;
  boardStrength: string | undefined;
  insideOnly: boolean | undefined;
  twoSided: boolean | undefined;
  kraftType: string | undefined;
  flute: string | undefined;
  sizes: DraftDesignSize[] = [];
  tags: DraftDesignTag[] = [];
  collection: DraftDesignCollection | undefined;
  boxType: ProductType | undefined;

  deserialize(input: any): DraftDesign {
    this.id = input.id;
    if (input.createdTime) {
      this.created = moment(input.createdTime).toDate();
    }
    if (input.updatedTime) {
      this.updated = moment(input.updatedTime).toDate();
    }
    this.createdBy = input.createdBy;
    this.updatedBy = input.updatedBy;
    this.name = input.name;
    this.description = input.description;
    this.displayOrder = input.displayOrder;
    if (input.screenshotUrl) {
      this.screenshotUrl = input.screenshotUrl;
    }
    this.subcategory = new Subcategory().deserialize(input.subcategory);
    this.boardType = input.boardType;
    this.boardStrength = input.boardStrength;
    this.insideOnly = input.insideOnly;
    this.twoSided = input.twoSided;
    this.kraftType = input.kraftType;
    this.flute = input.flute;
    this.sizes = [];
    if (input.sizes) {
      (input.sizes as any[]).forEach((size) => {
        this.sizes.push(new DraftDesignSize().deserialize(size));
      });
    }
    this.tags = [];
    if (input.tags) {
      (input.tags as any[]).forEach((tag) => {
        this.tags.push(new DraftDesignTag().deserialize(tag));
      });
    }
    if (input.collection) {
      this.collection = new DraftDesignCollection().deserialize(input.collection);
    }
    if (input.boxType) {
      this.boxType = new ProductType().deserialize(input.boxType);
    }

    return this;
  }

  get publishedCount(): number {
    let published = 0;
    this.sizes.forEach((size) => {
      if (size.published) {
        published += 1;
      }
    });
    return published;
  }

  togglePublished() {
    const publish = this.publishedCount < this.sizes.length;
    this.sizes.forEach((size) => {
      size.published = publish;
    });
  }

  get boardTypeText() {
    let boardType = this.kraftType;
    switch (boardType) {
      case "k":
        boardType = "Kraft";
        break;
      case "kw":
        boardType = "Mottled White";
        break;
      case "pkw":
        boardType = "Kemi";
        break;
    }
    return boardType;
  }

  get isAllSizesDeleted(): boolean {
    return this.sizes.map((previousValue) => previousValue.deleted).reduce((previous, current) => previous && current);
  }
}

export class Container implements ISerializable<Container> {
  name: string | undefined;
  files: ContainerFile[] = [];

  deserialize(input: any): Container {
    this.name = input.container;
    if (input.files) {
      (input.files as any[]).forEach((image) => {
        this.files.push(new ContainerFile().deserialize(image));
      });
    }
    return this;
  }
}

export class ContentType {
  type: string = "";
  subtype: string = "";

  deserialize(input: any): ContentType {
    this.type = input.type;
    this.subtype = input.subtype;
    return this;
  }

  get isImage() {
    return Mediatype.isImage(this.toString());
  }

  get isJson() {
    return Mediatype.isJson(this.toString());
  }

  get isPdf() {
    return Mediatype.isPdf(this.toString());
  }

  get isSvg() {
    return Mediatype.isSvg(this.toString());
  }

  get isText() {
    return Mediatype.isText(this.toString());
  }

  public toString = (): string => {
    return this.type + "/" + Mediatype.getSimpleSubtype(this.subtype ?? "");
  };
}

export class ContainerFile implements ISerializable<ContainerFile> {
  name: string | undefined;
  shortName: string | undefined;
  containerName: string | undefined;
  contentType: ContentType | undefined;
  length: string = "0";
  lengthReadable: string | undefined;
  content: string | undefined;
  created: Date | undefined;
  updated: Date | undefined;
  // get url() {
  //   if (this.containerName) {
  //     return `${this.environment.apiHost}/project/container/${this.containerName}/${this.name}`;
  //   }
  //   return `${this.environment.apiHost}/project/container/${this.name}`;
  // }
  deserialize(input: any): ContainerFile {
    this.name = input.name;
    this.shortName = input.short_name;
    if (input.shortName) {
      this.shortName = input.shortName;
    }
    this.containerName = input.containerName;

    this.contentType = input.contentType;
    this.length = input.length;
    this.lengthReadable = Size.convertAndFormat(this.length ?? "0");
    this.content = input.content;
    this.contentType = new ContentType().deserialize(input.contentType);
    this.created = moment(input.created).toDate();
    this.updated = moment(input.updated).toDate();
    return this;
  }
}

export class UserImage implements ISerializable<UserImage> {
  content: string | undefined;
  contentType: ContentType | undefined;
  name: string | undefined;
  length: string = "0";
  lengthReadable: string | undefined;
  created: Date | undefined;
  updated: Date | undefined;

  deserialize(input: any): UserImage {
    this.content = input.content;
    if (input.contentType) {
      this.contentType = new ContentType().deserialize(input.contentType);
    } else if (input["content-type"]) {
      this.contentType = new ContentType().deserialize(input["content-type"]);
    }
    this.name = input.name;
    this.length = input.length;
    this.lengthReadable = Size.convertAndFormat(this.length ?? "0");
    this.created = input.created;
    this.updated = input.updated;
    return this;
  }
}
