import { Component, signal, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import { PageHeaderComponent } from './features/page-header/page-header.component';
import { PageFooterComponent } from './features/page-footer/page-footer.component';
import LogRocket from "logrocket";
import { AlertComponent } from 'ngx-bootstrap/alert';
import { AlertService } from '../../../libs/services/src/lib/alert.service';

@Component({
    selector: "app-root",
    standalone: true,
    imports: [RouterOutlet, PageHeaderComponent, PageFooterComponent, AlertComponent],
    templateUrl: "./app.component.html",
    styleUrl: "./app.component.scss",
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  private title = environment.config.title;
  isAlert = signal<boolean>(false)

  location!: Location;

  constructor(
    private titleService: Title,
    public alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.title);

    if (environment.production) {
      if (location.protocol === "http:") {
        window.location.href = location.href.replace("http", "https");
      }
      LogRocket.init('edplti/companybox');
    }
  }
}
