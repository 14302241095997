import { Component, inject, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TermsComponent } from '../terms/terms.component';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';

@Component({
    selector: "app-page-footer",
    standalone: true,
    imports: [TermsComponent, PrivacyPolicyComponent],
    templateUrl: "./page-footer.component.html",
    styleUrl: "./page-footer.component.scss"
})
export class PageFooterComponent {
  currentYear = new Date().getFullYear();
  modal: any;
  bsModalService = inject(BsModalService);

  showModal(template: TemplateRef<any>) {
    this.modal = this.bsModalService.show(template);
  }
}
