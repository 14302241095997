import {AuditedSerializable} from './base';
import {BoardStrength, BoardType} from './types';

export class Category extends AuditedSerializable<Category> {
  code: string | undefined;
  name: string | undefined;
  description: string | undefined;
  includeInProducts: boolean | undefined;
  subcategories: Subcategory[] = [];

  override deserialize(input: any): Category {
    super.deserialize(input);
    this.code = input.code;
    this.name = input.name;
    this.description = input.description;
    this.includeInProducts = input.includeInProducts;
    if (input.subcategories) {
      this.subcategories = [];
      (input.subcategories as any[]).forEach((subcategory) => {
        this.subcategories.push(new Subcategory().deserialize(subcategory));
      });
    }
    return this;
  }
}

export class ProductImage extends AuditedSerializable<Subcategory> {
  code: string | undefined;
  location: string | undefined;
  default: boolean | undefined;

  override deserialize(input: any): ProductImage {
    super.deserialize(input);
    return this;
  }
}

export class Subcategory extends AuditedSerializable<Subcategory> {
  code: string | undefined;
  name: string | undefined;
  secondaryName: string | undefined;
  allowCustomSizes: boolean | undefined;
  displayPremiumWhite: boolean | undefined;
  displaySecondaryPremiumWhite: boolean | undefined;
  displayWhite: boolean | undefined;
  displaySecondaryWhite: boolean | undefined;
  displayKraft: boolean | undefined;
  displaySecondaryKraft: boolean | undefined;
  displayTwoSided: boolean | undefined;
  displayLuxe: boolean | undefined;
  displayStandard: boolean | undefined;
  displayNoPrint: boolean | undefined;
  allowCustomSample: boolean | undefined;
  rushEnabled: boolean | undefined;
  images: ProductImage[] = [];
  boardTypes: BoardType[] = [];
  category: Category | undefined;

  presets: Preset[] = [];
  isBoxStyle: boolean | undefined;

  get isDHC() {
    return this.code === "DHC";
  }
  get isRPM() {
    return this.code === "RPM";
  }

  override deserialize(input: any): Subcategory {
    super.deserialize(input);
    this.code = input.code;
    this.name = input.name;
    this.secondaryName = input.secondaryName;
    this.allowCustomSizes = input.allowCustomSizes;
    this.displayPremiumWhite = input.displayPremiumWhite;
    this.displaySecondaryPremiumWhite = input.displaySecondaryPremiumWhite;
    this.displayWhite = input.displayWhite;
    this.displaySecondaryWhite = input.displaySecondaryWhite;
    this.displayKraft = input.displayKraft;
    this.displaySecondaryKraft = input.displaySecondaryKraft;
    this.displayTwoSided = input.displayTwoSided;
    this.displayLuxe = input.displayLuxe;
    this.displayStandard = input.displayStandard;
    this.displayNoPrint = input.displayNoprint;
    this.allowCustomSample = input.allowCustomSample;
    this.rushEnabled = input.rushEnabled;
    this.isBoxStyle = input.isBoxStyle;
    if (input.images) {
      (input.images as any[]).forEach((image) => {
        this.images.push(new ProductImage().deserialize(image));
      });
    }
    if (input.boardTypes) {
      (input.boardTypes as any[]).forEach((boardType) => {
        this.boardTypes.push(new BoardType().deserialize(boardType));
      });
    }
    if (input.category) {
      this.category = new Category().deserialize(input.category);
    }
    return this;
  }
}

export class ProductType extends AuditedSerializable<ProductType> {
  name: string | undefined;
  description: string | undefined;
  code: string | undefined;
  aeCode: string | undefined;
  boardTypeCode: string | undefined;
  widthScore: number | undefined;
  lengthScore: number | undefined;
  tuck: number | undefined;
  flap: number | undefined;
  minimumOrder: number | undefined;
  maxQuantity: number | undefined;
  defaultQuantity: number | undefined;
  boardType: BoardType | undefined;
  subcategory: Subcategory | undefined;

  override deserialize(input: any): ProductType {
    super.deserialize(input);
    this.name = input.name;
    this.description = input.description;
    this.code = input.code;
    this.aeCode = input.aeCode;
    this.boardTypeCode = input.boardTypeCode;
    this.widthScore = input.widthScore;
    this.lengthScore = input.lengthScore;
    this.tuck = input.tuck;
    this.flap = input.flap;
    this.minimumOrder = input.minimumOrder;
    this.maxQuantity = input.maxQuantity;
    this.defaultQuantity = input.defaultQuantity;
    if (input.boardType) {
      this.boardType = new BoardType().deserialize(input.boardType);
    }
    if (input.subcategory) {
      this.subcategory = new Subcategory().deserialize(input.subcategory);
    }
    return this;
  }
}

export class Preset extends AuditedSerializable<Preset> {
  code: string | undefined;
  label: string | undefined;
  length: number | undefined;
  width: number | undefined;
  depth: number | undefined;
  type: ProductType | undefined;
  boardStrengths: BoardStrength[] = [];

  override deserialize(input: any): Preset {
    super.deserialize(input);
    this.code = input.code;
    this.length = input.length;
    this.width = input.width;
    this.depth = input.depth;
    this.label = input.label ? input.label : this.length + " x " + this.width + " x " + this.depth;
    if (input.type) {
      this.type = new ProductType().deserialize(input.type);
    }
    if (input.boardStrengths) {
      this.boardStrengths = [];
      (input.boardStrengths as any[]).forEach((boardStrength) => {
        this.boardStrengths.push(new BoardStrength().deserialize(boardStrength));
      });
    }
    return this;
  }
}

export class SelectablePreset {
  label: string | undefined;
  code: string | undefined;
  value: Preset | undefined;
  presets: Preset[] = [];

  static fromCategory(name: string, code: string) {
    const box = new SelectablePreset();
    box.label = name.replace(/<sup>TM<\/sup>/i, "(TM)"); // TODO: do this in a better way
    box.code = code;
    box.presets = [];
    return box;
  }
}

