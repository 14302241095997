<div class="page-container">
  @if (pageLoading) {
  <app-loading-modal [content]="'loading box details'"></app-loading-modal>
  } @if (cartLoading) {
  <app-loading-modal [content]="'loading cart'"> </app-loading-modal>
  }
  <app-page-content-wrapper [goBack]="goBack.bind(this)">
    <div class="page-title">
      <h1>Review & Add to Cart</h1>
    </div>
    <div class="page-content">
      <div class="row">
        <div class="col-1 left-column">
          <div>
            <button
              (click)="setActiveButton('outsideArt')"
              [ngClass]="{
                active: activeButton === 'outsideArt',
                inactive: activeButton !== 'outsideArt'
              }"
            >
              <img src="assets/cb/images/upload_art_white.svg" />
            </button>
            <div class="btn-label">Outside Art</div>
          </div>
          <div>
            <button
              (click)="setActiveButton('insideArt')"
              [ngClass]="{
                active: activeButton === 'insideArt',
                inactive: activeButton !== 'insideArt'
              }"
            >
              <img src="assets/cb/images/upload_art_white.svg" />
            </button>
            <div class="btn-label">Inside Art</div>
          </div>
        </div>
        <div class="col-8 center-column">
          @if (activeButton === 'insideArt') {
          <img [src]="insideImage" class="w-100" />
          } @if (activeButton === 'outsideArt') {
          <img [src]="outsideImage" class="w-100" />
          }
        </div>
        <div class="col-3 right-column">
          <div class="box-details-container">
            @if (!isPaddedMailer) {
            <h6 class="column-button">My Box Details</h6>
            } @if (isPaddedMailer) {
            <h6 class="column-button">My Mailer Details</h6>
            }
            <div
              class="column-header"
              [innerHTML]="state().productDetails.categoryName?.toUpperCase()"
            ></div>
            <div class="details-content">
              <div>
                {{ getWholeNumber(length) }}@if
                (convertDecimalToFraction(length)[0] !== '0') {
                <span
                  ><sup>{{ convertDecimalToFraction(length)[0] }}</sup
                  >&frasl;<sub>{{
                    convertDecimalToFraction(length)[1]
                  }}</sub></span
                >
                } x
                {{ getWholeNumber(width) }}@if
                (convertDecimalToFraction(width)[0] !== '0') {
                <span
                  ><sup>{{ convertDecimalToFraction(width)[0] }}</sup
                  >&frasl;<sub>{{
                    convertDecimalToFraction(width)[1]
                  }}</sub></span
                >
                } @if (getWholeNumber(depth) === 0) {
                <span> (in)</span>
                }
                <!-- Don't display the depth if it is equal to 0 -->
                @if (getWholeNumber(depth) !== 0) {
                <span>
                  x {{ getWholeNumber(depth) }}@if
                  (convertDecimalToFraction(depth)[0] !== '0') {
                  <span
                    ><sup>{{ convertDecimalToFraction(depth)[0] }}</sup
                    >&frasl;<sub>{{
                      convertDecimalToFraction(depth)[1]
                    }}</sub></span
                  >
                  } (in)</span
                >
                }
              </div>
              <div>{{ getPrintingSide() }} Printing</div>
              <div>
                {{ state().productDetails.BoardStrength }} ECT
                {{ state().productDetails.outerMaterial }} /
                {{ state().productDetails.innerMaterial }}
              </div>
            
            </div>

            <mat-label for="quantity"
              ><span class="qty-label">Quantity</span>
            </mat-label>
            @if (!customQuantityEntered) { 
              @if (!isPizza && !isPaddedMailer) {
            <mat-select
              id="quantity"
              name="quantity"
              [(ngModel)]="selectedPrice"
              placeholder="{{ selectedPrice.quantity | number }} ({{
                selectedPrice.pricePerUnit | currency
              }}) each"
            >
              @for (price of prices; track trackByFn($index, price)) {
              <mat-option [value]="price">
                {{ price.quantity | number }} ({{
                  price.pricePerUnit | currency
                }}
                each)
              </mat-option>
              }
            </mat-select>
            } @if (isPizza) {
            <mat-select
              id="quantity"
              name="quantity"
              [(ngModel)]="selectedPrice"
              placeholder="{{ selectedPrice.quantity | number }} ({{
                selectedPrice.pricePerUnit | currency
              }}) each"
            >
              @for (price of prices; track trackByFn($index, price)) {
              <mat-option [value]="price">
                {{ price.quantity | number }} ({{
                  price.pricePerUnit | currency
                }}
                each)
              </mat-option>
              }
            </mat-select>
            } 
             @if (isPaddedMailer) {
            <mat-select
              id="quantity"
              name="quantity"
              [(ngModel)]="selectedPrice"
              [disabled]="!prices"
              [placeholder]="selectedPrice.label"
            >
              @for (price of prices|slice:0:standardQuantities.length; track
              trackByFn(i, price); let i = $index) {
              <mat-option (click)="quantityArrayIndex = i" [value]="price">
                {{ price.label }}
              </mat-option>
              }
            </mat-select>
            } }

            <!-- <input matInput (focusin)="showModal(customQuantity)" [(ngModel)]="enteredCustomPrice" *ngIf="customQuantityEntered"> -->
            @if (!customQuantityEntered && !isPizza) {
            <a class="quantity-link" (click)="showModal(customQuantity)"
              >Enter a Custom Quantity</a
            >
            } @if (customQuantityEntered) {
            <a
              class="quantity-link"
              (click)="
                customQuantityEntered = !customQuantityEntered;
                customQuantityViolation = false;
                selectedPrice = prices[0]
              "
              >Select a Preset Quantity</a
            >
            } @if (customQuantityViolation) {
            <div class="custom-quantity-disclaimer">
              This item has a minimum quantity of @if (state().productDetails.luxe) {
              <span>{{ prices[0].luxeMinimumOrder }}</span>
              }@if (!state().productDetails.luxe) {
              <span>{{ prices[0].minimumOrder }}</span>
              } and a maximum quantity of
              {{ prices[0].maxQuantity }}. Please adjust your quantity to meet
              these requirements.
            </div>
            }
            <div class="sample-offer">
              Note: to receive a sample of your completed box, select a quantity
              of 1. No digital proof will be emailed for orders with a quantity
              of 1.
            </div>
            <hr>
            <div class="subtotal-container">
              <div class="column-header">
                SUBTOTAL: {{ selectedPrice.totalPrice | currency }}
              </div>
            </div>
            <div class="approval-container">
              <div style="font-weight: 600">Approval</div>
              <div>
                A <span style="font-weight: 600">digital proof</span> of your
                custom box will be emailed within 24 hours after order
                submission. You must
                <span style="font-weight: 600">approve the digital proof</span>
                before your box goes into production. If you don't receive your
                proof email, please check your spam or junk folder.
              </div>
              <div>
                I have verified the preview to be correct and will confirm
                approval upon receipt of the proof.
              </div>
              <div>
                I understand that once the checkout process is completed,
                <span style="font-weight: 600"
                  >no changes or cancellations can be made</span
                >.
              </div>
              <div>
                <mat-checkbox
                  (click)="disableBtn.set(!disableBtn())"
                  [checked]="disableBtn()"
                >
                  I accept and approve my artwork.</mat-checkbox
                >
              </div>
            </div>
            <div class="button-container">
              <button
                type="button"
                [disabled]="!disableBtn()"
                (click)="addToCart()"
                class="white-submit"
              >
                ADD TO CART
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-page-content-wrapper>
  <form name="addToCart" #addToCartForm ngNoForm [action]="cartUrl()" method="post">
    <input name="action" type="hidden" value="add" />
    <input name="cxml-urlencoded" type="hidden" [value]="cxmlurlencoded()" />
  </form>
</div>

<ng-template #customQuantity>
  <div class="modal-content custom-quantity">
    <div class="modal-body">
      <div class="container-fluid">
        <mat-label for="customQuantity">Enter Custom Quantity</mat-label><br />
        <input
          matInput
          (keyup.enter)="getPrice(); hide()"
          (keypress)="numberOnly($event)"
          [ngModel]="state().quantity"
          (ngModeelChange)="stateSvc.updateState({quantity: $event})"
          name="customQuantity"
          autocomplete="off"
        />
      </div>
      <div class="button-container">
        <button type="button" class="white-submit" (click)="getPrice(); hide()">
          OK
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #notFoundModal>
  <app-not-found-modal></app-not-found-modal>
</ng-template>
