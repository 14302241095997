import {AuditedSerializable, ISerializable} from './base';

export class BoardStrength extends AuditedSerializable<BoardStrength> {

    code: string | undefined;
    name: string | undefined;
    strength: string | undefined;

    override deserialize(input: any): BoardStrength {
        super.deserialize(input);
        this.code = input.code;
        this.name = input.name;
        this.strength = input.strength;
        return this;
    }

}

export class BoardType extends AuditedSerializable<BoardType> {

    code: string | undefined;
    name: string | undefined;
    flute: string | undefined;
    thickness: number | undefined;
    boardStrengths: BoardStrength[] = [];

    override deserialize(input: any): BoardType {
        super.deserialize(input);
        this.code = input.code;
        this.name = input.name;
        this.flute = input.flute;
        this.thickness = input.thickness;
        if (input.boardStrengths) {
            this.boardStrengths = [];
            (input.boardStrengths as any[]).forEach((boardStrength) => {
                this.boardStrengths.push(new BoardStrength().deserialize(boardStrength));
            });
        }
        return this;
    }
}

export class PaperType implements ISerializable<PaperType> {

    id: string | undefined;
    name: string | undefined;
    printTypes: PrintType[] = [];
    secondary: SecondaryPaperType[] = [];

    get luxeAllowed() {
        return this.isAllowed(new PrintType().deserialize({id: 'luxe'}));
    }

    isAllowed(printType: PrintType) {
        const pt = this.printTypes.find(value => {
            return value.id === printType.id;
        });
        // console.log('printType', printType, pt);
        return pt !== undefined && pt.allowed;
    }

    deserialize(input: any): PaperType {
        this.id = input.id;
        this.name = input.name;
        this.printTypes = input.printTypes || [];
        this.secondary = input.secondary || [];
        return this;
    }
}

export class SecondaryPaperType implements ISerializable<SecondaryPaperType> {

    id: string | undefined;
    name: string | undefined;
    default: boolean | undefined;

    deserialize(input: any): SecondaryPaperType {
        this.id = input.id;
        this.name = input.name;
        this.default = input.default;
        return this;
    }

}

export class PrintType implements ISerializable<PrintType> {

    id: string | undefined;
    name: string | undefined;
    allowed: boolean | undefined;
    finishes: string[] = [];

    get glossAllowed() {
        return this.finishes.indexOf('Gloss') !== -1;
    }

    deserialize(input: any): PrintType {
        this.id = input.id;
        this.name = input.name;
        this.allowed = input.allowed;
        this.finishes = input.finishes || [];
        return this;
    }
}

export class Material extends AuditedSerializable<Material> {

    code: string | undefined;
    paper: string | undefined;
    weightPerSquareFoot: number | undefined;
    boardStrength: BoardStrength | undefined;
    boardType: BoardType | undefined;
    premium: boolean | undefined;
    paperType: string | undefined;

    override deserialize(input: any): Material {
        super.deserialize(input);
        this.code = input.code;
        this.paper = input.paper;
        this.weightPerSquareFoot = input.weightPerSquareFoot;
        if (input.boardStrength) {
            this.boardStrength = new BoardStrength().deserialize(input.boardStrength);
        }
        if (input.boardType) {
            this.boardType = new BoardType().deserialize(input.boardType);
        }
        this.premium = input.premium;
        this.paperType = input.paperType;
        return this;
    }
}

export class ShippedBox {
    name: string | undefined;
    length: number | undefined;
    width: number | undefined;
    depth: number | undefined;
    weight: number | undefined;
    quantity: number | undefined;

}
